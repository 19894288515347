var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "country-summary" }, [
    _c("div", { staticClass: "scroll" }, [
      _c(
        "div",
        { staticClass: "graph-wrapper" },
        [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-wrapper" }, [
      _c("p", { staticClass: "updated-info" }, [
        _vm._v(
          "\n      " +
            _vm._s(this.$translations("common", "updated_date")) +
            " : " +
            _vm._s(_vm.lastUpdatedDate) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "scroll" }, [
        _c("ul", { staticClass: "summary-tables" }, [
          _c("li", { staticClass: "row r-header clearfix" }, [
            _c("div", { staticClass: "col" }),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col1.name")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col2.name")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col3.name")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col4.name")) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.country.english_name) +
                  " Portfolio\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            1,000.0\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.lastPrice, 1)) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.summary.long_only)) +
                  "%\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.summary.long_mdd)) +
                  "%\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.country.english_name) +
                  " Portfolio(L/S)\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            1,000.0\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.lastLSPrice, 1)) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.summary.long_short)) +
                  "%\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.summary.long_short_mdd)) +
                  "%\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            " + _vm._s(_vm.bmName) + "\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            1,000.0\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.lastBmPrice, 1)) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.summary.bm)) +
                  "%\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.summary.bm_mdd)) +
                  "%\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.bm2Name != null
            ? _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.bm2Name) + "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v("\n            1,000.0\n          ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("formatNumber")(_vm.lastBm2Price, 1)) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("formatNumber")(_vm.summary.bm2)) +
                      "%\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("formatNumber")(_vm.summary.bm2_mdd)) +
                      "%\n          "
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }