<template>
  <layout>
    <div class="banner-container">
      <div class="container">
        <p class="page-title">
          {{ this.$translations('common', 'menu.about')}}
        </p>

        <div class="content">
          <p class="title">
            {{ this.$translations('common', 'title')}}
          </p>

          <div class="description">
            <p>
              {{trans('banner.description1')}}
            </p>
            <p>
              {{trans('banner.description2')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="about-container container bg-w">
      <div class="content">
        <p class="page-name n-12">
          {{ this.$translations('common', 'menu.home')}} > {{ this.$translations('common', 'menu.about')}}
        </p>

        <div class="section">
          <p class="section-name">
            {{trans('summary.name')}}
          </p>

          <p class="section-title">
            {{trans('summary.section.title')}}
          </p>

          <div class="section-desc">
            <p>
              {{trans('summary.section.desc')}}
            </p>
          </div>
        </div>

        <ul class="items clearfix">
          <li class="item" :class="getLocale">
            <p class="item-title">
              {{trans('summary.item1.title')}}
            </p>

            <div class="item-desc">
              <p>{{trans('summary.item1.desc1')}}</p>
            </div>
          </li>

          <li class="item">
            <p class="item-title">
              {{trans('summary.item2.title')}}
            </p>

            <div class="item-desc">
              <p>{{trans('summary.item2.desc1')}}</p>
            </div>
          </li>

          <li class="item">
            <p class="item-title">
              {{trans('summary.item3.title')}}
            </p>

            <div class="item-desc">
              <p>{{trans('summary.item3.desc1')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="about-container container bg-b">
      <div class="content">
        <div class="section">
          <p class="section-name">
            {{trans('algorithm.section.name')}}
          </p>

          <p class="section-title">
            {{trans('algorithm.section.title')}}
          </p>

          <div class="section-desc">
            <p>
              {{trans('algorithm.section.desc')}}
            </p>
          </div>
        </div>

        <div class="analysis-wrapper clearfix" :class="getLocale">
          <ul class="data-list clearfix">
            <li class="data">
              <p class="data-title">
                {{trans('algorithm.data1.title')}}
              </p>

              <div class="data-con">
                <p>
                  {{trans('algorithm.data1.item1')}}
                </p>
                <p>
                  {{trans('algorithm.data1.item2')}}
                </p>
                <p>
                  {{trans('algorithm.data1.item3')}}
                </p>
              </div>
            </li>
            <li class="data">
              <p class="data-title">
                {{trans('algorithm.data2.title')}}
              </p>

              <div class="data-con">
                <p>
                  {{trans('algorithm.data2.item1')}}
                </p>
                <p>
                  {{trans('algorithm.data2.item2')}}
                </p>
              </div>
            </li>
            <li class="data">
              <p class="data-title">
                {{trans('algorithm.data3.title')}}
              </p>

              <div class="data-con">
                <p>
                  {{trans('algorithm.data3.item1')}}
                </p>
                <p>
                  {{trans('algorithm.data3.item2')}}
                </p>
              </div>
            </li>
            <li class="data">
              <p class="data-title">
                {{trans('algorithm.data4.title')}}
              </p>

              <div class="data-con">
                <p>
                  {{trans('algorithm.data4.item1')}}
                </p>
                <p>
                  {{trans('algorithm.data4.item2')}}
                </p>
                <p>
                  {{trans('algorithm.data4.item3')}}
                </p>
              </div>
            </li>
            <li class="data">
              <p class="data-title">
                {{trans('algorithm.data5.title')}}
              </p>

              <div class="data-con">
                <p>
                  {{trans('algorithm.data5.item1')}}
                </p>
                <p>
                  {{trans('algorithm.data5.item2')}}
                </p>
                <p>
                  {{trans('algorithm.data5.item3')}}
                </p>
              </div>
            </li>
            <li class="data">
              <p class="data-title">
                {{trans('algorithm.data6.title')}}

              </p>

              <div class="data-con">
                <p>
                  {{trans('algorithm.data6.item1')}}

                </p>
                <p>
                  {{trans('algorithm.data6.item2')}}

                </p>
              </div>
            </li>
          </ul>

          <div class="data-arrow">
            <div class="arrow"></div>
          </div>

          <div class="big-data clearfix">
            <img :src="get_images('about', 'big_data')" class="img" />
            <div class="img-names">
              <p>
                {{ this.$translations('about', 'algorithm.image1.title1')}}
              </p>
              <p>
                {{ this.$translations('about', 'algorithm.image1.title2')}}
              </p>
            </div>
          </div>

          <div class="data-arrow">
            <div class="arrow"></div>
          </div>

          <div class="algorithm clearfix">
            <img :src="get_images('about', 'algorithm')" class="img" />
            <div class="img-names">
              <p>
                {{ this.$translations('about', 'algorithm.image2.title1')}}
              </p>
              <p>
                {{ this.$translations('about', 'algorithm.image2.title2')}}
              </p>
            </div>
          </div>

          <div class="data-arrow">
            <div class="arrow"></div>
          </div>

          <div class="stocks" :class="getLocale">
            <p>
              {{trans('algorithm.stocks.item1')}}
            </p>
            <p>
              {{trans('algorithm.stocks.item2')}}
            </p>
            <p>
              {{trans('algorithm.stocks.item3')}}
            </p>
            <p>
              {{trans('algorithm.stocks.item4')}}
            </p>
            <p>
              {{trans('algorithm.stocks.item5')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="about-container container bg-w">
      <div class="content">
        <div class="section">
          <p class="section-name">
            {{trans('summary.name')}}
          </p>

          <p class="section-title">
            {{trans('summary.section.title')}}
          </p>

          <div class="section-desc">
            <p>
              {{trans('summary.section.desc')}}
            </p>
          </div>
        </div>

        <ul class="strategy-items clearfix">
          <li class="item">
            <p class="num">
              50
            </p>
            <p class="item-title">
              {{trans('strategy.item1.title')}}

            </p>
            <div class="item-desc">
              <p>
                {{trans('strategy.item1.desc1')}}
              </p>
            </div>
          </li>

          <li class="item">
            <p class="num">
              3
            </p>
            <p class="item-title">
              {{trans('strategy.item2.title')}}
            </p>
            <div class="item-desc">
              <p>
                {{trans('strategy.item2.desc1')}}
              </p>
            </div>
          </li>

          <li class="item">
            <p class="num">
              4
            </p>
            <p class="item-title">
              {{trans('strategy.item3.title')}}
            </p>
            <div class="item-desc">
              <p>
                {{trans('strategy.item3.desc1')}}
              </p>
              <p>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="about-container container bg-b">
      <div class="content">
        <div class="section">
          <p class="section-name">
            {{trans('graph.section.name')}}
          </p>

          <p class="section-title">
            {{trans('graph.section.title')}}
          </p>

          <div class="section-desc">
            <p>
              {{trans('graph.section.desc')}}
            </p>
          </div>
        </div>

        <div class="graph-wrapper">
          <div class="exp-wrapper clearfix">
            <div class="terms">
              {{trans('graph.head.period')}} : 2001.6 ~ 2020.11 (78{{trans('graph.head.quarter')}})
            </div>
            <div class="country-info">
              BM : KOSPI / KOSDAQ Index
            </div>
          </div>

          <div class="scroll">
            <ul class="infos clearfix">
              <li class="info">
                <p class="rate">
                  57 (73%)
                </p>
                <p class="rate-desc">
                  {{trans('graph.data.item1')}}
                </p>
              </li>

              <li class="info">
                <p class="rate">
                  16.4%
                </p>
                <p class="rate-desc">
                  {{trans('graph.data.item2')}}
                </p>
              </li>

              <li class="info">
                <p class="rate">
                  -6.7%
                </p>
                <p class="rate-desc">
                  {{trans('graph.data.item3')}}
                </p>
              </li>

              <li class="info">
                <p class="rate">
                  40.7%
                </p>
                <p class="rate-desc">
                  {{trans('graph.data.item4')}}
                </p>
              </li>

              <li class="info">
                <p class="rate">
                  97,607%
                </p>
                <p class="rate-desc">
                  {{trans('graph.data.item5')}}
                </p>
              </li>
            </ul>
          </div>

          <div class="scroll">
            <div class="graph-img">
              <highcharts :options="chartOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-container container bg-w">
      <div class="content">
        <div class="section">
          <p class="section-name">
            {{trans('characteristic.section.name')}}
          </p>

          <p class="section-title">
            {{trans('characteristic.section.title')}}
          </p>

          <div class="section-desc">
            <p>
              {{trans('characteristic.section.desc')}}
            </p>
          </div>
        </div>

        <ul class="characteristic-items clearfix">
          <li class="item">
            <div class="item-title" :class="getLocale">
              <p>
                {{trans('characteristic.item1.title')}}
              </p>
            </div>

            <div class="item-desc">
              <p class="desc-title">
                {{trans('characteristic.item1.desc')}}
              </p>
              <div class="desc-rate">
                <span class="rate">39.7</span><span class="unit">%</span>
              </div>
            </div>
          </li>

          <li class="item">
            <div class="item-title" :class="getLocale">
              <p>
                {{trans('characteristic.item2.title')}}
              </p>
            </div>

            <div class="item-desc">
              <ul class="desc-graphs clearfix">
                <li class="graph">
                  <img :src="get_images('about', 'long_100')" class="g-img" />
                  <div class="g-desc">
                    <p>
                      {{trans('characteristic.item2.desc1')}}
                    </p>
                  </div>
                </li>

                <li class="graph">
                  <img :src="get_images('about', 'long_70')" class="g-img" />
                  <div class="g-desc">
                    <p>
                      {{trans('characteristic.item2.desc2')}}
                    </p>
                    <p>
                      {{trans('characteristic.item2.desc3')}}
                    </p>
                  </div>
                </li>

                <li class="graph">
                  <img :src="get_images('about', 'long_50')" class="g-img" />
                  <div class="g-desc">
                    <p>
                      {{trans('characteristic.item2.desc4')}}
                    </p>
                    <p>
                      {{trans('characteristic.item2.desc5')}}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </li>

        </ul>
      </div>
    </div>

  </layout>
</template>

<script>
  import Layout from '../commons/layout';
  export default {
    name: 'home',
    data() {
      return {
        accumulated: "https://s3.ap-northeast-2.amazonaws.com/assets.fmway.io/images/about/3.svg",
        chartOptions: {
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
        },
      };
    },
    computed: {
      getLocale: function() {
        return window.I18n.locale;
      }
    },
    components: {
      Layout
    },
    mounted() {
      this.initComponent()
    },
    methods: {
      trans: function(key) {
        const val = window.I18n.messages['about'][key] || '';
        return val;
      },
      initComponent: function() {
        axios.get(`${window.apiHost}/simulations/about`, {}).then(res => {
          this.simulation = res.data;
          this.countryMarkets = res.data.country_markets;

          if (res.data.country_markets == null) {
            this.$router.push({name: 'simulation'});
          }

          var bmName = '';
          var otherBmName = null;
          res.data.country_markets.forEach((item, idx) => {
            if (item.is_short === true) {
              bmName = item.english_name;
            } else if (item.is_short !== true) {
              otherBmName = item.english_name;
            }
          });

          if (bmName.length == 0 && otherBmName.length > 0) {
            bmName = otherBmName;
            otherBmName = null;
          }

          var xaxis = [];
          var portfolioRate = [];
          var bm1 = [];
          var bm2 = [];
          res.data.performances.forEach(function (item, idx) {
            xaxis.push(item.year + "." + item.quarter + "Q");
            portfolioRate.push(item.accumulated_portfolio_earning);
            bm1.push(item.accumulated_bm1_earning);

            if (item.accumulated_bm2_earning != null) {
              bm2.push(item.accumulated_bm2_earning);
            }
          });

          const options = {
            title: {
              text: ''
            },
            subtitle: {
              text: ''
            },
            // X축 설정
            xAxis: {
              reversed: false,
              categories: xaxis,
              gridLineWidth: 1,
              tickInterval: 3,
              startOnTick: true,
            },
            // Y축 설정
            yAxis: [
              {
                title: {
                  text: 'Profit'
                },
                labels: {
                  format: '{value} %'
                }
              }
            ],

            // 오른쪽 범례설정
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            },
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              }
            },
            // 라인 설정
            series: [
              {
                name: `Long Only`,
                data: portfolioRate,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              },
              {
                name: `${bmName}`,
                data: bm1,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              },
            ],
            colors: ['#FF5C00', '#2DBBC4', '#004589'],
          };

          if (otherBmName != null && bm2.length > 0 && otherBmName.length > 0) {
            options.series.push({
              name: `${otherBmName}`,
              data: bm2,
              marker: {
                radius: 0,
                symbol: 'circle'
              }
            });
          }

          this.chartOptions = options;
        });
      },
      get_images: (page, key) => {
        const val = window.I18n.images[page][key] || '';
        return val;
      }

    }
  }
</script>