<template>
  <div style="height: auto;position: relative" :class="{w: (isWhiteHeader && !showMobileMenu)}">
    <div class='header clearfix' :class="{w: (isWhiteHeader && !showMobileMenu)}">
      <div class="menu-wrapper clearfix" :class="{w: (isWhiteHeader && !showMobileMenu)}">
        <router-link :to="{name: 'home'}" class="logo">
        </router-link>

        <ul class="menus clearfix">
          <li class="menu kr-14" :class="{active: isActive('home')}" @mouseenter="hideSubMenu(['simulation', 'portfolio'])">
            <router-link :to="{ name: 'home' }">
              {{ this.$translations('common', 'menu.home')}}
            </router-link>
          </li>
          <li class="menu kr-14" :class="{active: isActive('about')}" @mouseenter="hideSubMenu(['simulation', 'portfolio'])">
            <router-link :to="{ name: 'about' }">
              {{ this.$translations('common', 'menu.about')}}
            </router-link>
          </li>
          <li class="menu kr-14" :class="{active: isActive('simulation')}" @mouseenter="hideSubMenu(['portfolio'])">
            <router-link :to="{ name: 'simulation' }">
              {{ this.$translations('common', 'menu.simulation')}}
            </router-link>
          </li>
          <li class="menu kr-14" :class="{active: isActive('portfolio')}" @mouseenter="hideSubMenu(['simulation'])">
            <router-link :to="{ name: 'portfolio' }">
              {{ this.$translations('common', 'menu.portfolio')}}
            </router-link>
          </li>
          <li class="menu kr-14" :class="{active: isActive('community')}" @mouseenter="hideSubMenu(['simulation', 'portfolio'])">
            <router-link :to="{ name: 'community' }">
              {{ this.$translations('common', 'menu.community')}}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="mobile-menu" :class="{w: (isWhiteHeader && !showMobileMenu), close: showMobileMenu}" v-on:click="setMobileMenu"></div>
      <div class="lang-wrapper">
        <div class="clearfix">
          <div class="current-flag flag" :class="locale" v-on:click="showLocale = !showLocale"/>
          <div class="down" :class="{w: isWhiteHeader}"></div>
        </div>
        <ul class="flags" v-if="showLocale">
          <li class="flag ko" v-on:click="setLocale('ko')" />
          <li class="flag en" v-on:click="setLocale('en')" />
          <li class="flag zh" v-on:click="setLocale('zh')" />
        </ul>
      </div>
    </div>

    <mobile-menu v-if="showMobileMenu" :simulations="simulations" :portfolios="portfolios" @hide-mobile-menu="setMobileMenu"/>

    <div class="sub-header-menus clearfix" :class="{w: isWhiteHeader}" v-if="displaySubMenu"  @mouseleave="displaySubMenu = false">
      <ul class="menu-list">
        <li class="menu" v-on:click="displaySubMenu = false">
          <router-link :to="{ name: 'simulation' }">
            {{ this.$translations('common', 'menu.summary')}}
          </router-link>
        </li>
        <li v-for="simulation in simulations" :key="simulation.id" class="menu" v-on:click="displaySubMenu = false">
          <router-link :to="{ name: 'country_simulation', params: {id: simulation.id} }">
            {{translateSimulName(simulation)}}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="sub-header-menus portfolios clearfix" :class="{w: isWhiteHeader}" v-if="displayPortfolioSubMenu"  @mouseleave="displayPortfolioSubMenu = false">
      <ul class="menu-list portfol">
        <li class="menu" v-on:click="displayPortfolioSubMenu = false">
          <router-link :to="{ name: 'portfolio' }">
            {{ this.$translations('common', 'menu.all')}}
          </router-link>
        </li>
        <li v-for="portfolio in portfolios" :key="portfolio.id" class="menu" v-on:click="displayPortfolioSubMenu = false">
          <router-link :to="{ name: 'country_portfolio', params: {countryId: portfolio.id} }">
            {{translatePortName(portfolio)}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import MobileMenu from "./mobile_menu";

  export default {
    data: function() {
      return {
        displaySubMenu: false,
        displayPortfolioSubMenu: false,
        locale: window.I18n.locale,
        availableLocales: window.I18n.availableLocales,
        showLocale: false,
        portfolios: [],
        simulations: [],
        showMobileMenu: false,
      }
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      },
      isWhiteHeader: function() {
        const whiteHeader = ['home'];
        return whiteHeader.indexOf(this.$route.name) !== -1;
      },
    },
    beforeMount() {
      axios.get(`${window.apiHost}/simulations/submenus`).then(res => {
        this.simulations = res.data;
      });

      axios.get(`${window.apiHost}/portfolios/submenus`).then(res => {
        this.portfolios = res.data;
      });
    },
    methods: {
      activeOn: function(paths) {
        if(paths.includes(this.$route.name)) {
          return 'active';
        } else {
          return '';
        }
      },
      setLocale: function(locale) {
        this.$cookies.set("locale", locale);
        location.reload();
      },
      isActive: function(routeName) {
        return this.$route.name == routeName;
      },
      setMobileMenu: function() {
        this.showMobileMenu = !this.showMobileMenu;
      },
      hideSubMenu: function(menus) {
        if (menus.includes('portfolio')) {
          this.displayPortfolioSubMenu = false;
        } else {
          this.displayPortfolioSubMenu = true;
        }
        if (menus.includes('simulation')) {
          this.displaySubMenu = false;
        } else {
          this.displaySubMenu = true;
        }
      },
      translateSimulName: function(simulation) {
        if (this.locale == 'zh') {
          return simulation.chinese_country_name;
        } else {
          return simulation.english_country_name;
        }
      },
      translatePortName: function (portfolio) {
        if (this.locale == 'zh') {
          return portfolio.chinese_name;
        } else {
          return portfolio.english_name.toUpperCase();
        }
      },
    },
    watch: {
    },
    components: {
      MobileMenu
    }
  }
</script>