var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "banner-container simulation-banner",
        style: _vm.backgroundImage("simulation", "banner")
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "page-title b-24" }, [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.simulation")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "banner-title" }, [
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("simulation", "banner.title1")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("simulation", "banner.title2")) +
                    "\n          "
                )
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "simulation-container container" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "page-name n-12" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.$translations("common", "menu.home")) +
              " > " +
              _vm._s(this.$translations("common", "menu.simulation")) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "top-wrapper",
            style: _vm.backgroundImage("simulation", "top")
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.simulation")) +
                "\n      "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "container-summary" }, [
          _c("p", { staticClass: "summary-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("simulation", "summary.title")) +
                "\n\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("simulation", "summary.desc1")) +
                  "\n\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("simulation", "summary.desc2")) +
                  "\n\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("simulation", "summary.desc3")) +
                  "\n\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "back-test-wrapper" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v("\n          Back test Simulation Summary\n        ")
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "items clearfix" }, [
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "top-wra" }, [
                _c("p", { staticClass: "item-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item1.title")
                      ) +
                      "\n\n              "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "item-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item1.desc")
                      ) +
                      "\n\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "rate-wrapper" }, [
                _c("div", { staticClass: "rate-desc", class: _vm.locale }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "backtest.item1.ratedesc"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item1.rate")
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "unit" }, [_vm._v("%")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "top-wra" }, [
                _c("p", { staticClass: "item-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item2.title")
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "rate-wrapper" }, [
                _c("div", { staticClass: "rate-desc", class: _vm.locale }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "backtest.item2.ratedesc"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item2.rate")
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "unit" }, [_vm._v("%")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "top-wra" }, [
                _c("p", { staticClass: "item-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item3.title")
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "rate-wrapper" }, [
                _c("div", { staticClass: "rate-desc", class: _vm.locale }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "backtest.item3.ratedesc"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item3.rate")
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "unit" }, [_vm._v("%")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "top-wra" }, [
                _c("p", { staticClass: "item-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item4.title")
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "rate-wrapper" }, [
                _c("div", { staticClass: "rate-desc", class: _vm.locale }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "backtest.item4.ratedesc"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item4.rate")
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "unit" }, [_vm._v("%")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "top-wra" }, [
                _c("p", { staticClass: "item-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item5.title")
                      ) +
                      "\n\n              "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "item-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item5.desc")
                      ) +
                      "\n\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "rate-wrapper" }, [
                _c("p", { staticClass: "rate" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item5.rate")
                      )
                  ),
                  _c("span", { staticClass: "unit" }, [_vm._v("%")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c("div", { staticClass: "top-wra" }, [
                _c("p", { staticClass: "item-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item6.title")
                      ) +
                      "\n\n              "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "item-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item6.desc")
                      ) +
                      "\n\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "rate-wrapper" }, [
                _c("div", { staticClass: "rate-desc", class: _vm.locale }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "backtest.item6.ratedesc"
                          )
                        ) +
                        "\n\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("simulation", "backtest.item6.rate")
                      )
                  ),
                  _c("span", { staticClass: "unit" }, [_vm._v("%")])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "country-wrapper" }, [
          _c(
            "ul",
            { staticClass: "countries" },
            [
              _c("li", { staticClass: "row r-header clearfix" }, [
                _c("div", { staticClass: "no" }, [
                  _vm._v("\n              No.\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col1")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col2")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col3")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col4")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col5")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col6")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(this.$translations("simulation", "table.col7")) +
                      "\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.simulations, function(simulation, idx) {
                return _c(
                  "li",
                  { key: simulation.id, staticClass: "row clearfix" },
                  [
                    _c("div", { staticClass: "no" }, [
                      _vm._v(
                        "\n              " + _vm._s(idx + 1) + "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(simulation.country_name) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("formatNumber")(simulation.stock_count, 0)
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(simulation.investment_y_period) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(simulation.investment_t_period) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("formatNumber")(simulation.winning_rate)
                          ) +
                          "%\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("formatNumber")(
                              simulation.avg_t_earning_rate
                            )
                          ) +
                          "%\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("formatNumber")(
                              simulation.avg_y_earning_rate
                            )
                          ) +
                          "%\n            "
                      )
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _c("li", { staticClass: "row sum clearfix" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n              SUM\n            ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("formatNumber")(_vm.sumOfStocks, 0)) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("formatNumber")(_vm.sumOfYear, 0)) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("formatNumber")(_vm.sumOfQuarter, 0)) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("formatNumber")(_vm.avgOfWinning)) +
                      "%\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("formatNumber")(_vm.avgOfEarningQuarter)) +
                      "%\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("formatNumber")(_vm.avgOfEarningYear)) +
                      "%\n            "
                  )
                ])
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }