var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "country-summary" }, [
    _c("div", { staticClass: "scroll" }, [
      _c(
        "div",
        { staticClass: "graph-wrapper" },
        [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-wrapper" }, [
      _c("p", { staticClass: "updated-info" }, [
        _vm._v(
          "\n      " +
            _vm._s(this.$translations("common", "updated_date")) +
            " : " +
            _vm._s(_vm.lastUpdatedDate) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "scroll" }, [
        _c("ul", { staticClass: "summary-tables" }, [
          _c("li", { staticClass: "row r-header clearfix" }, [
            _c("div", { staticClass: "col" }),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col1.name")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col2.name")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col3.name")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.col4.name")) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.country.english_name) +
                  " Portfolio\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            1,000.0\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.lastPrice, 1)) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.country.summary.quarter_long_only
                    )
                  ) +
                  "%\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatNumber")(_vm.country.summary.quarter_long_mdd)
                  ) +
                  "%\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.country.english_name) +
                  " Portfolio(L/S)\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            1,000.0\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.lastLSPrice, 1)) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.country.summary.quarter_long_short
                    )
                  ) +
                  "%\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.country.summary.quarter_long_short_mdd
                    )
                  ) +
                  "%\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.country.bm_name) + "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n            1,000.0\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatNumber")(_vm.lastBmPrice, 1)) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.country.summary.quarter_composite
                    )
                  ) +
                  "%\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.country.summary.quarter_composite_mdd
                    )
                  ) +
                  "%\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.country.bm2_name != null
            ? _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.country.bm2_name) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v("\n            1,000.0\n          ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("formatNumber")(_vm.lastBm2Price, 1)) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("formatNumber")(
                          _vm.country.summary.quarter_bm2_composite
                        )
                      ) +
                      "%\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("formatNumber")(
                          _vm.country.summary.quarter_bm2_composite_mdd
                        )
                      ) +
                      "%\n          "
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stocks-wrapper clearfix" }, [
        _c(
          "ul",
          { staticClass: "stocks" },
          [
            _c("li", { staticClass: "row r-header clearfix" }, [
              _c("div", { staticClass: "name-wrapper" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      this.$translations("portfolio", "stocks.col1.name")
                    ) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "price-wrapper" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      this.$translations("portfolio", "stocks.col2.name")
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.splitPortfolios(0), function(portfolio) {
              return _c(
                "li",
                {
                  key: portfolio.id,
                  staticClass: "row clearfix",
                  on: {
                    click: function($event) {
                      return _vm.selectPortfolio(portfolio.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "name-wrapper" }, [
                    _c("p", { staticClass: "symbol" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(portfolio.stock.symbol.toUpperCase()) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.stockName(portfolio.stock)) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-wrapper clearfix" }, [
                    _c("div", { staticClass: "price" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("formatNumber")(portfolio.stock.price, 1)
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "stocks" },
          [
            _c("li", { staticClass: "row r-header clearfix" }, [
              _c("div", { staticClass: "name-wrapper" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      this.$translations("portfolio", "stocks.col1.name")
                    ) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "price-wrapper" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      this.$translations("portfolio", "stocks.col2.name")
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.splitPortfolios(1), function(portfolio) {
              return _c(
                "li",
                {
                  key: portfolio.id,
                  staticClass: "row clearfix",
                  on: {
                    click: function($event) {
                      return _vm.selectPortfolio(portfolio.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "name-wrapper" }, [
                    _c("p", { staticClass: "symbol" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(portfolio.stock.symbol.toUpperCase()) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.stockName(portfolio.stock)) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-wrapper clearfix" }, [
                    _c("div", { staticClass: "price" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("formatNumber")(portfolio.stock.price, 1)
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]
              )
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }