<template>
  <layout >
    <div class="jb-box">
      <video muted="true" autoplay loop playsinline>
        <source src="https://s3.ap-northeast-2.amazonaws.com/assets.fmway.io/videos/fmway_vod.mp4" type="video/mp4">
        <strong>Your browser does not support the video tag.</strong>
      </video>
      <div class="home-container">
        <div class="container">
          <div class="content">
            <p class="title">
              {{ this.$translations('common', 'title')}}
            </p>

            <p class="title">
              FMWAY
            </p>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<style>
  .jb-text hr {
    width: 120px;
  }
</style>

<script>
  import Layout from '../commons/layout';
  export default {
    name: 'home',
    data() {
      return {
      };
    },
    components: {
      Layout
    }
  }
</script>