var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "jb-box" }, [
      _c(
        "video",
        {
          attrs: { muted: "true", autoplay: "", loop: "", playsinline: "" },
          domProps: { muted: true }
        },
        [
          _c("source", {
            attrs: {
              src:
                "https://s3.ap-northeast-2.amazonaws.com/assets.fmway.io/videos/fmway_vod.mp4",
              type: "video/mp4"
            }
          }),
          _vm._v(" "),
          _c("strong", [_vm._v("Your browser does not support the video tag.")])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "home-container" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "content" }, [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("common", "title")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "title" }, [
              _vm._v("\n            FMWAY\n          ")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }