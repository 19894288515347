<template>
  <div class="stock-container pop-wrapper">
    <div class="dimBg" v-on:click="closePop"></div>

    <div class="pop-layer">
      <div class="pop-header clearfix">
        <div class="close-btn" v-on:click="closePop">
          <i class="material-icons">close</i>
        </div>
      </div>

      <div class="graph-wrapper">
        <p class="graph-title">
          {{portfolio.stock.english_name}}
        </p>

        <div class="scroll">
          <div class="graph">
            <highcharts :options="chartOptions"/>
          </div>
        </div>
      </div>

      <div class="scroll">
        <div class="table-wrapper">
          <p class="updated-info">
            {{ this.$translations('common', 'updated_date')}} : {{lastUpdatedDate}}
          </p>

          <ul class="summary-tables">
            <li class="row r-header clearfix">
              <div class="col">
              </div>
              <div class="col">
                {{ this.$translations('portfolio', 'summary.col1.name')}}
              </div>
              <div class="col">
                {{ this.$translations('portfolio', 'summary.col2.name')}}
              </div>
              <div class="col">
                {{ this.$translations('portfolio', 'summary.col3.name')}}
              </div>
              <div class="col">
                {{ this.$translations('portfolio', 'summary.col4.name')}}
              </div>
            </li>

            <li class="row clearfix">
              <div class="col ellipsis" style="padding: 0 10px;" :data-tooltip="portfolioInfo.name">
                {{portfolioInfo.name}}
              </div>
              <div class="col">
                {{portfolioInfo.startPrice | formatNumber}}
              </div>
              <div class="col">
                {{portfolioInfo.lastPrice | formatNumber}}
              </div>
              <div class="col">
                {{portfolioInfo.ratio | formatNumber}}%
              </div>
              <div class="col">
                {{portfolioInfo.mdd | formatNumber}}%
              </div>
            </li>

            <li class="row clearfix">
              <div class="col">
                {{bm1Info.name}}
              </div>
              <div class="col">
                {{bm1Info.startPrice | formatNumber}}
              </div>
              <div class="col">
                {{bm1Info.lastPrice | formatNumber}}
              </div>
              <div class="col">
                {{bm1Info.ratio | formatNumber}}%
              </div>
              <div class="col">
                {{bm1Info.mdd | formatNumber}}%
              </div>
            </li>

            <li class="row clearfix" v-if="bm2Info.name != null">
              <div class="col">
                {{bm2Info.name}}
              </div>
              <div class="col">
                {{bm2Info.startPrice | formatNumber}}
              </div>
              <div class="col">
                {{bm2Info.lastPrice | formatNumber}}
              </div>
              <div class="col">
                {{bm2Info.ratio | formatNumber}}%
              </div>
              <div class="col">
                {{bm2Info.mdd | formatNumber}}%
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      portfolioId: {
        type: Number
      }
    },
    data() {
      return {
        portfolio: {
          stock: {
            english_name: ''
          }
        },
        chartOptions: {
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
        },
        portfolioInfo: {
          name: null,
          startPrice: 0,
          lastPrice: 0,
          mdd: 0,
        },
        bm1Info: {
          name: null,
          startPrice: 0,
          lastPrice: 0,
          mdd: 0,
        },
        bm2Info: {
          name: null,
          startPrice: 0,
          lastPrice: 0,
          mdd: 0,
        },
        lastUpdatedDate: '',
        hasBm2: false,
      }
    },
    mounted() {
      const curDom = this;
      axios.get(`${window.apiHost}/portfolios/${this.portfolioId}`).then(res => {
        curDom.portfolio = res.data;
      });

      axios.get(`${window.apiHost}/portfolios/${this.portfolioId}/chart`).then(res => {
        var xaxis = [];
        var portfolioRate = [];
        var bm1 = [];
        var bm2 = [];

        this.portfolioInfo.name = res.data.name;
        this.bm1Info.name = res.data.bm1_name;
        this.bm2Info.name = res.data.bm2_name;

        this.portfolioInfo.mdd = res.data.mdd;
        this.bm1Info.mdd = res.data.bm1_mdd;
        this.bm2Info.mdd = res.data.bm2_mdd;

        res.data.chart_data.forEach(function (item, idx) {
          xaxis.push(item.trade_date);
          portfolioRate.push(item.ratio);
          bm1.push(item.bm1_ratio);
          bm2.push(item.bm2_ratio);

          if (idx == 0) {
            curDom.portfolioInfo.startPrice = item.price;
            curDom.bm1Info.startPrice = item.bm1_price;
            curDom.bm2Info.startPrice = item.bm2_price;
          }

          if (item.ratio != null) {
            curDom.lastUpdatedDate = item.trade_date;

            curDom.portfolioInfo.lastPrice = item.price;
            curDom.bm1Info.lastPrice = item.bm1_price;
            curDom.bm2Info.lastPrice = item.bm2_price;

            curDom.portfolioInfo.ratio = item.ratio;
            curDom.bm1Info.ratio = item.bm1_ratio;
            curDom.bm2Info.ratio = item.bm2_ratio;
          }
        });

        const options = {
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          // X축 설정
          xAxis: {
            reversed: false,
            categories: xaxis,
            gridLineWidth: 1,
            tickInterval: 3,
            startOnTick: true,
          },
          // Y축 설정
          yAxis: [
            {
              title: {
                text: 'Profit'
              },
              labels: {
                format: '{value} %'
              }
            }
          ],

          // 오른쪽 범례설정
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            }
          },
          // 라인 설정
          series: [
            {
              name: curDom.portfolioInfo.name,
              data: portfolioRate,
              marker: {
                radius: 0,
                symbol: 'circle'
              }
            },
            {
              name: curDom.bm1Info.name,
              data: bm1,
              marker: {
                radius: 0,
                symbol: 'circle'
              }
            },
          ],
          colors: ['#FF5C00', '#2DBBC4', '#004589'],
        };

        if (curDom.bm2Info.name != null) {
          options.series.push({
            name: curDom.bm2Info.name,
            data: bm2,
            marker: {
              radius: 0,
              symbol: 'circle'
            }
          })
        }

        curDom.chartOptions = options;
      });
    },
    computed: {
    },
    methods: {
      closePop: function() {
        this.$emit('close');
      }
    }
  }
</script>