var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "banner-container portfolio-banner",
        style: _vm.backgroundImage("portfolio", "banner")
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "page-title b-24" }, [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.portfolio")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "banner-title" }, [
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("portfolio", "banner.title1")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("portfolio", "banner.title2")) +
                    "\n          "
                )
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "portfolio-container container" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "page-name n-12" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.$translations("common", "menu.home")) +
              " > " +
              _vm._s(this.$translations("common", "menu.portfolio")) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "top-wrapper",
            style: _vm.backgroundImage("portfolio", "top")
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.portfolio")) +
                "\n      "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "summary-wrapper" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("portfolio", "summary.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.desc1")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("portfolio", "summary.desc2")) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scroll" }, [
            _c("ul", { staticClass: "tab" }, [
              _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "head" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("portfolio", "summary.table.head1")
                      ) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations(
                          "portfolio",
                          "summary.table.head1.val1"
                        )
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "head" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("portfolio", "summary.table.head2")
                      ) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations(
                          "portfolio",
                          "summary.table.head2.val1"
                        )
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "head" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("portfolio", "summary.table.head3")
                      ) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "portfolio",
                            "summary.table.head3.val1"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "portfolio",
                            "summary.table.head3.val2"
                          )
                        ) +
                        "\n\n                "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "head" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("portfolio", "summary.table.head4")
                      ) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "portfolio",
                            "summary.table.head4.val1"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "portfolio",
                            "summary.table.head4.val2"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "head" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("portfolio", "summary.table.head5")
                      ) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v("\n                2020.06.01\n              ")
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "row clearfix" }, [
                _c("div", { staticClass: "head" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations("portfolio", "summary.table.head6")
                      ) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        this.$translations(
                          "portfolio",
                          "summary.table.head6.val1"
                        )
                      ) +
                      "\n                " +
                      _vm._s(_vm.quarterStartDate) +
                      "\n              "
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "outcome-wrapper" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("portfolio", "outcome.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tab-wrapper" }, [
            _c("p", { staticClass: "tab-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    this.$translations("portfolio", "outcome.table.title1")
                  ) +
                  " : " +
                  _vm._s(_vm.days) +
                  " " +
                  _vm._s(
                    this.$translations("portfolio", "outcome.table.title2")
                  ) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "scroll" }, [
              _c(
                "ul",
                { staticClass: "tab" },
                [
                  _c("li", { staticClass: "head row clearfix" }, [
                    _c("div", { staticClass: "country" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(
                            this.$translations(
                              "portfolio",
                              "outcome.table.col1"
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "portf clearfix" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          "\n                    Long only\n                  "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "res clearfix" }, [
                        _c("div", { staticClass: "rate" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                this.$translations(
                                  "portfolio",
                                  "outcome.table.col2"
                                )
                              ) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mdd" }, [
                          _vm._v(
                            "\n                      MDD\n                    "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "portf clearfix" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          "\n                    Long Short\n                  "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "res clearfix" }, [
                        _c("div", { staticClass: "rate" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                this.$translations(
                                  "portfolio",
                                  "outcome.table.col3"
                                )
                              ) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mdd" }, [
                          _vm._v(
                            "\n                      MDD\n                    "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bm" }, [
                      _vm._v("\n                  BM\n                ")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.portfolios, function(portfolio) {
                    return _c(
                      "li",
                      { key: portfolio.id, staticClass: "row clearfix" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "country_portfolio",
                                params: { countryId: portfolio.id }
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "country" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(portfolio.name) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "rate" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("formatNumber")(portfolio.long_only)
                                  ) +
                                  "%\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mdd" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("formatNumber")(portfolio.long_mdd)
                                  ) +
                                  "%\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "rate" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("formatNumber")(portfolio.long_short)
                                  ) +
                                  "%\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mdd" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      portfolio.long_short_mdd
                                    )
                                  ) +
                                  "%\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "bm" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm._f("formatNumber")(portfolio.bm)) +
                                  "%\n                  "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }