<template>
  <div class="footer">
    <div class="container">
      <div class="content">
        <div class="logo-wrapper clearfix">
          <router-link :to="{name: 'home'}" class="logo">
          </router-link>

          <ul class="menus clearfix">
            <li class="menu">
              <router-link :to="{ name: 'about' }">
                {{ this.$translations('common', 'menu.about')}}
              </router-link>
            </li>
            <li class="menu">
              <router-link :to="{ name: 'simulation' }">
                {{ this.$translations('common', 'menu.simulation')}}
              </router-link>
            </li>
            <li class="menu">
              <router-link :to="{ name: 'portfolio' }">
                {{ this.$translations('common', 'menu.portfolio')}}
              </router-link>
            </li>
            <li class="menu">
              <router-link :to="{ name: 'community' }">
                {{ this.$translations('common', 'menu.community')}}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="copyright-wrapper clearfix">
          <p class="company">FMway  Co., Ltd.</p>
          <p class="mail">
            <a href="mailto:fmwayinvest@gmail.com">fmwayinvest@gmail.com</a>
          </p>

          <p class="copyright">
            Copyright ⓒ 2021 FMway Invest All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  }
</script>