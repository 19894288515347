export default {
  install(Vue) {
    Vue.prototype.$translations = function(page, key) {
      const val = window.I18n.messages[page][key] || '';
      return val;
    }

    Vue.prototype.$images = function(page, key) {

    }
  }
}