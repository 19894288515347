<template>
  <layout>
    <div class="banner-container portfolio-banner" :style="backgroundImage('portfolio', 'banner')">
      <div class="container">
        <p class="page-title b-24">
          {{ this.$translations('common', 'menu.portfolio')}}
        </p>

        <div class="content">
          <div class="banner-title">
            <p>
              {{ this.$translations('portfolio', 'banner.title1')}}
            </p>
            <p>
              {{ this.$translations('portfolio', 'banner.title2')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="portfolio-container container">
      <div class="content">
        <p class="page-name n-12">
          {{ this.$translations('common', 'menu.home')}} > {{ this.$translations('common', 'menu.portfolio')}}
        </p>

        <div class="top-wrapper" :style="backgroundImage('portfolio', 'top')">
          {{ this.$translations('common', 'menu.portfolio')}}
        </div>

        <div class="summary-wrapper">
          <p class="title">
            {{ this.$translations('portfolio', 'summary.title')}}
          </p>

          <div class="desc">
            <p>
              {{ this.$translations('portfolio', 'summary.desc1')}}
            </p>
            <p>
              {{ this.$translations('portfolio', 'summary.desc2')}}
            </p>
          </div>

          <div class="scroll">
            <ul class="tab">
              <li class="row clearfix">
                <div class="head">
                  {{ this.$translations('portfolio', 'summary.table.head1')}}
                </div>
                <div class="val">
                  {{ this.$translations('portfolio', 'summary.table.head1.val1')}}
                </div>
              </li>

              <li class="row clearfix">
                <div class="head">
                  {{ this.$translations('portfolio', 'summary.table.head2')}}
                </div>
                <div class="val">
                  {{ this.$translations('portfolio', 'summary.table.head2.val1')}}
                </div>
              </li>

              <li class="row clearfix">
                <div class="head">
                  {{ this.$translations('portfolio', 'summary.table.head3')}}
                </div>
                <div class="val">
                  <p>
                    {{ this.$translations('portfolio', 'summary.table.head3.val1')}}
                  </p>
                  <p>
                    {{ this.$translations('portfolio', 'summary.table.head3.val2')}}

                  </p>
                </div>
              </li>

              <li class="row clearfix">
                <div class="head">
                  {{ this.$translations('portfolio', 'summary.table.head4')}}
                </div>
                <div class="val">
                  <p>
                    {{ this.$translations('portfolio', 'summary.table.head4.val1')}}
                  </p>
                  <p>
                    {{ this.$translations('portfolio', 'summary.table.head4.val2')}}
                  </p>
                </div>
              </li>

              <li class="row clearfix">
                <div class="head">
                  {{ this.$translations('portfolio', 'summary.table.head5')}}
                </div>
                <div class="val">
                  2020.06.01
                </div>
              </li>

              <li class="row clearfix">
                <div class="head">
                  {{ this.$translations('portfolio', 'summary.table.head6')}}
                </div>
                <div class="val">
                  {{ this.$translations('portfolio', 'summary.table.head6.val1')}}
                  {{ quarterStartDate }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="outcome-wrapper">
          <p class="title">
            {{ this.$translations('portfolio', 'outcome.title')}}
          </p>

          <div class="tab-wrapper">
            <p class="tab-title">
              {{ this.$translations('portfolio', 'outcome.table.title1')}} : {{days}} {{ this.$translations('portfolio', 'outcome.table.title2')}}
            </p>

            <div class="scroll">
              <ul class="tab">
                <li class="head row clearfix">
                  <div class="country">
                    {{ this.$translations('portfolio', 'outcome.table.col1')}}
                  </div>
                  <div class="portf clearfix">
                    <div class="name">
                      Long only
                    </div>

                    <div class="res clearfix">
                      <div class="rate">
                        {{ this.$translations('portfolio', 'outcome.table.col2')}}
                      </div>
                      <div class="mdd">
                        MDD
                      </div>
                    </div>
                  </div>

                  <div class="portf clearfix">
                    <div class="name">
                      Long Short
                    </div>

                    <div class="res clearfix">
                      <div class="rate">
                        {{ this.$translations('portfolio', 'outcome.table.col3')}}
                      </div>
                      <div class="mdd">
                        MDD
                      </div>
                    </div>
                  </div>

                  <div class="bm">
                    BM
                  </div>
                </li>

                <li class="row clearfix" v-for="portfolio in portfolios" :key="portfolio.id">
                  <router-link :to="{ name: 'country_portfolio', params: {countryId: portfolio.id} }">
                    <div class="country">
                      {{portfolio.name}}
                    </div>
                    <div class="rate">
                      {{portfolio.long_only | formatNumber}}%
                    </div>
                    <div class="mdd">
                      {{portfolio.long_mdd | formatNumber}}%
                    </div>
                    <div class="rate">
                      {{portfolio.long_short| formatNumber}}%
                    </div>
                    <div class="mdd">
                      {{portfolio.long_short_mdd | formatNumber}}%
                    </div>
                    <div class="bm">
                      {{portfolio.bm | formatNumber}}%
                    </div>
                  </router-link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../commons/layout';
  export default {
    name: 'portfolios',
    data() {
      return {
        portfolios: [],
        days: 0,
        quarterStartDate: null,
      };
    },
    components: {
      Layout
    },
    mounted() {
      axios.get(`${window.apiHost}/portfolios/summary`, {}).then(res => {
        this.portfolios = res.data.summaries;
        this.days = res.data.days;
        this.quarterStartDate = res.data.start_date;
      })
    },
    methods: {
      get_images: (page, key) => {
        const val = window.I18n.images[page][key] || '';
        return val;
      },
      backgroundImage: function(page, key) {
        const imageUrl = this.get_images(page, key);
        return `background-image: url('${imageUrl}')`;
      },
    }
  }
</script>