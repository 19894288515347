var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-menu-container container" }, [
    _c("div", { staticClass: "content" }, [
      _c("ul", { staticClass: "menus" }, [
        _c(
          "li",
          { staticClass: "menu", class: { active: _vm.isActive("home") } },
          [
            _c("router-link", { attrs: { to: { name: "home" } } }, [
              _vm._v(
                "\n          " +
                  _vm._s(this.$translations("common", "menu.home")) +
                  "\n        "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "menu", class: { active: _vm.isActive("about") } },
          [
            _c("router-link", { attrs: { to: { name: "about" } } }, [
              _vm._v(
                "\n          " +
                  _vm._s(this.$translations("common", "menu.about")) +
                  "\n        "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "sub-menu-wrapper clearfix",
            class: { active: _vm.showSimulation },
            on: {
              click: function($event) {
                _vm.showSimulation = !_vm.showSimulation
              }
            }
          },
          [
            _c("div", { staticClass: "lang" }, [
              _vm._v(
                "\n          " +
                  _vm._s(this.$translations("common", "menu.simulation")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon" })
          ]
        ),
        _vm._v(" "),
        _vm.showSimulation
          ? _c(
              "ul",
              { staticClass: "sub-menus" },
              [
                _c(
                  "li",
                  { staticClass: "sub-menu clearfix" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "simulation" } } },
                      [
                        _c("span", { on: { click: _vm.clicked } }, [
                          _vm._v(
                            _vm._s(this.$translations("common", "menu.summary"))
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.simulations, function(simulation) {
                  return _c(
                    "li",
                    { key: simulation.id, staticClass: "sub-menu clearfix" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "country_simulation",
                              params: { id: simulation.id }
                            }
                          }
                        },
                        [
                          _c("span", { on: { click: _vm.clicked } }, [
                            _vm._v(_vm._s(_vm.translate(simulation)))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "sub-menu-wrapper clearfix",
            class: { active: _vm.showPortfolio },
            on: {
              click: function($event) {
                _vm.showPortfolio = !_vm.showPortfolio
              }
            }
          },
          [
            _c("div", { staticClass: "lang" }, [
              _vm._v(
                "\n          " +
                  _vm._s(this.$translations("common", "menu.portfolio")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon" })
          ]
        ),
        _vm._v(" "),
        _vm.showPortfolio
          ? _c(
              "ul",
              { staticClass: "sub-menus" },
              [
                _c(
                  "li",
                  { staticClass: "sub-menu clearfix" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "portfolio" } } },
                      [
                        _c("span", { on: { click: _vm.clicked } }, [
                          _vm._v(
                            _vm._s(this.$translations("common", "menu.all"))
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.portfolios, function(portfolio) {
                  return _c(
                    "li",
                    { key: portfolio.id, staticClass: "sub-menu clearfix" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "country_portfolio",
                              params: { countryId: portfolio.id }
                            }
                          }
                        },
                        [
                          _c("span", { on: { click: _vm.clicked } }, [
                            _vm._v(_vm._s(_vm.translatePortName(portfolio)))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "menu", class: { active: _vm.isActive("community") } },
          [
            _c("router-link", { attrs: { to: { name: "community" } } }, [
              _vm._v(
                "\n          " +
                  _vm._s(this.$translations("common", "menu.community")) +
                  "\n        "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "sub-menu-wrapper clearfix",
            class: { active: _vm.showLanguage },
            on: {
              click: function($event) {
                _vm.showLanguage = !_vm.showLanguage
              }
            }
          },
          [
            _c("div", { staticClass: "lang" }, [_vm._v("Language")]),
            _vm._v(" "),
            _c("div", { staticClass: "icon" })
          ]
        ),
        _vm._v(" "),
        _vm.showLanguage
          ? _c("ul", { staticClass: "sub-menus" }, [
              _c(
                "li",
                {
                  staticClass: "sub-menu clearfix",
                  on: {
                    click: function($event) {
                      return _vm.setLocale("ko")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flag ko" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flag-name" }, [_vm._v("한국어")])
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "sub-menu clearfix",
                  on: {
                    click: function($event) {
                      return _vm.setLocale("en")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flag en" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flag-name" }, [_vm._v("English")])
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "sub-menu clearfix",
                  on: {
                    click: function($event) {
                      return _vm.setLocale("zh")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flag zh" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flag-name" }, [_vm._v("简体中文")])
                ]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }