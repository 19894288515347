var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c(
        "div",
        {
          staticClass: "banner-container portfolio-banner",
          style: _vm.backgroundImage("portfolio", "banner")
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("p", { staticClass: "page-title b-24" }, [
              _vm._v(
                "\n        " +
                  _vm._s(this.$translations("common", "menu.portfolio")) +
                  " / " +
                  _vm._s(_vm.country.english_name) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "banner-title" }, [
                _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(this.$translations("portfolio", "banner.title1")) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(this.$translations("portfolio", "banner.title2")) +
                      "\n          "
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "portfolio-container container" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("p", { staticClass: "page-name n-12" }, [
              _vm._v(
                "\n        " +
                  _vm._s(this.$translations("common", "menu.home")) +
                  " > " +
                  _vm._s(this.$translations("common", "menu.portfolio")) +
                  " > " +
                  _vm._s(_vm.country.english_name) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "country-info" }, [
              _c("p", { staticClass: "name" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.country.english_name) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "infos clearfix" }, [
                _c("li", { staticClass: "info" }, [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(this.$translations("common", "detail.target")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-desc" }, [
                    _c("p", [_vm._v(_vm._s(_vm.bmNames))]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(this.$translations("common", "detail.list"))
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "info" }, [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(this.$translations("common", "detail.period")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "info-desc" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.portfolioStartDate) +
                        " ~ " +
                        _vm._s(
                          this.$translations("common", "detail.period.present")
                        ) +
                        "\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "info" }, [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v("\n              BM\n            ")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "info-desc" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.bmNames) +
                        "\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "info" }, [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          this.$translations("portfolio", "summary.col3.name")
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "info-desc" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm._f("formatNumber")(_vm.getLongOnly)) +
                        "%\n            "
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tabs clearfix" }, [
              _c(
                "div",
                {
                  staticClass: "tab",
                  class: { active: _vm.tab == 0 },
                  on: {
                    click: function($event) {
                      _vm.tab = 0
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.country.english_name) +
                      " Portfolio\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab",
                  class: { active: _vm.tab == 1 },
                  on: {
                    click: function($event) {
                      _vm.tab = 1
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.country.english_name) +
                      " Accumulated\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.tab == 0
              ? _c("country-portfolio-index", {
                  on: { clicked: _vm.clickedPortfolio }
                })
              : _vm.tab == 1
              ? _c("country-accumulated")
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.portfolioPop
        ? _c("portfolio-popup", {
            attrs: { portfolioId: _vm.selectedPortfolioId },
            on: { close: _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }