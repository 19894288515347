<template>
  <div class="country-summary">
    <div class="scroll">
      <div class="graph-wrapper">
        <highcharts :options="chartOptions" />
      </div>
    </div>

    <div class="table-wrapper">
      <p class="updated-info">
        {{ this.$translations('common', 'updated_date')}} : {{lastUpdatedDate}}
      </p>

      <div class="scroll">
        <ul class="summary-tables">
          <li class="row r-header clearfix">
            <div class="col">
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col1.name')}}
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col2.name')}}
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col3.name')}}
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col4.name')}}
            </div>
          </li>

          <li class="row clearfix">
            <div class="col">
              {{country.english_name}} Portfolio
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastPrice | formatNumber(1)}}
            </div>
            <div class="col">
              {{country.summary.quarter_long_only | formatNumber}}%
            </div>
            <div class="col">
              {{country.summary.quarter_long_mdd | formatNumber}}%
            </div>
          </li>

          <li class="row clearfix">
            <div class="col">
              {{country.english_name}} Portfolio(L/S)
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastLSPrice | formatNumber(1)}}
            </div>
            <div class="col">
              {{country.summary.quarter_long_short | formatNumber}}%
            </div>
            <div class="col">
              {{country.summary.quarter_long_short_mdd | formatNumber}}%
            </div>
          </li>

          <li class="row clearfix">
            <div class="col">
              {{country.bm_name}}
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastBmPrice | formatNumber(1)}}
            </div>
            <div class="col">
              {{country.summary.quarter_composite | formatNumber}}%
            </div>
            <div class="col">
              {{country.summary.quarter_composite_mdd | formatNumber}}%
            </div>
          </li>

          <li class="row clearfix" v-if="country.bm2_name != null">
            <div class="col">
              {{country.bm2_name}}
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastBm2Price | formatNumber(1)}}
            </div>
            <div class="col">
              {{country.summary.quarter_bm2_composite| formatNumber}}%
            </div>
            <div class="col">
              {{country.summary.quarter_bm2_composite_mdd | formatNumber}}%
            </div>
          </li>
        </ul>
      </div>

      <div class="stocks-wrapper clearfix">
        <ul class="stocks">
          <li class="row r-header clearfix">
            <div class="name-wrapper">
              {{ this.$translations('portfolio', 'stocks.col1.name')}}
            </div>
            <div class="price-wrapper">
              {{ this.$translations('portfolio', 'stocks.col2.name')}}
            </div>
          </li>
          <li class="row clearfix" v-on:click="selectPortfolio(portfolio.id)" v-for="portfolio in splitPortfolios(0)" :key="portfolio.id">
            <div class="name-wrapper">
              <p class="symbol">
                {{portfolio.stock.symbol.toUpperCase()}}
              </p>
              <p class="name">
                {{stockName(portfolio.stock)}}
              </p>
            </div>
            <div class="price-wrapper clearfix">
              <div class="price">
                {{portfolio.stock.price | formatNumber(1)}}
              </div>
            </div>
          </li>
        </ul>

        <ul class="stocks">
          <li class="row r-header clearfix">
            <div class="name-wrapper">
              {{ this.$translations('portfolio', 'stocks.col1.name')}}
            </div>
            <div class="price-wrapper">
              {{ this.$translations('portfolio', 'stocks.col2.name')}}
            </div>
          </li>
          <li class="row clearfix" v-on:click="selectPortfolio(portfolio.id)" v-for="portfolio in splitPortfolios(1)" :key="portfolio.id">
            <div class="name-wrapper">
              <p class="symbol">
                {{portfolio.stock.symbol.toUpperCase()}}
              </p>
              <p class="name">
                {{stockName(portfolio.stock)}}
              </p>
            </div>
            <div class="price-wrapper clearfix">
              <div class="price">
                {{portfolio.stock.price| formatNumber(1)}}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
    },
    data() {
      return {
        country: {
          english_name: '',
          summary: {}
        },
        portfolios: [],
        chartOptions: {
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
        },
        lastUpdatedDate: '',
      }
    },
    computed: {
      lastPrice: function() {
        const longOnly = this.country.summary.quarter_long_only;
        return longOnly === undefined ? 1000 : ((100 + longOnly) * 10);
      },
      lastLSPrice: function() {
        const longShort = this.country.summary.quarter_long_short;
        return longShort === undefined ? 1000 : ((100 + longShort) * 10);
      },
      lastBmPrice: function() {
        const longShort = this.country.summary.quarter_composite;
        return longShort === undefined ? 1000 : ((100 + longShort) * 10);
      },
      lastBm2Price: function() {
        const longShort = this.country.summary.quarter_bm2_composite;
        return longShort === undefined ? 1000 : ((100 + longShort) * 10);
      }
    },
    mounted() {
      this.initComponent();
    },
    methods: {
      initComponent: function() {
        const curDom = this;
        axios.get(`${window.apiHost}/portfolios/country?id=${this.$route.params.countryId}`, {}).then(res => {
          curDom.country = res.data;
          var country = res.data;
          var xaxis = [];
          var longOnlySeries = [];
          var longShortSerires = [];
          var bm = [];
          var bm2 = [];

          let bmName, bm2Name;

          if (country != null) {
            curDom.portfolios = country.portfolios;
            const countryName = country.english_name;
            let startBm;

            bmName = country.bm_name;
            bm2Name = country.bm2_name;
            this.bm2Name = bm2Name;

            country.quarter_accumulated.forEach(function (item, idx) {
              // if (idx == 0) {
              //   startBm = item.composite;
              // }

              xaxis.push(item.trade_date);
              longOnlySeries.push(item.quarter_long_only);
              longShortSerires.push(item.quarter_long_short);
              // bm.push(item.composite != null ? (item.composite - startBm) : null);

              bm.push(item.quarter_composite);
              bm2.push(item.quarter_bm2_composite);
            });

            this.lastUpdatedDate = country.last_updated_date;

            const options = {
              title: {
                text: ''
              },
              subtitle: {
                text: ''
              },
              // X축 설정
              xAxis: {
                reversed: false,
                categories: xaxis,
                gridLineWidth: 1,
                tickInterval: 3,
                startOnTick: true,
              },
              // Y축 설정
              yAxis: [
                {
                  title: {
                    text: 'Profit'
                  },
                  labels: {
                    format: '{value} %'
                  }
                }
              ],

              // 오른쪽 범례설정
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              },
              plotOptions: {
                series: {
                  label: {
                    connectorAllowed: false,
                  },
                }
              },
              // 라인 설정
              series: [
                {
                  name: `${countryName} Portfolio`,
                  data: longOnlySeries,
                  marker: {
                    radius: 0,
                    symbol: 'circle'
                  }
                },
                {
                  name: `${countryName} Portfolio(Long Short)`,
                  data: longShortSerires,
                  marker: {
                    radius: 0,
                    symbol: 'circle'
                  }
                },
                {
                  name: bmName,
                  data: bm,
                  marker: {
                    radius: 0,
                    symbol: 'circle'
                  }
                }
              ],
              colors: ['#FF5C00', '#82D443', '#2DBBC4', '#004589'],
            };

            if (bm2Name !== undefined && bm2Name != null) {
              options.series.push({
                name: bm2Name,
                data: bm2,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              });
            }

            curDom.chartOptions = options;
          }
        });
      },
      splitPortfolios: function(idx) {
        if (idx == 0) {
          return this.portfolios.slice(0, (this.portfolios.length / 2));
        } else {
          return this.portfolios.slice(this.portfolios.length / 2);
        }
      },
      rateText: function(rate) {
        if (rate === undefined || rate === 0) {
          return "-";
        } else if (rate > 0) {
          return `+${rate}%`;
        } else {
          return `${rate}%`;
        }
      },
      selectPortfolio: function(portfolioId) {
        this.$emit("clicked", portfolioId);
      },
      stockName: function(stock) {
        if (window.I18n.locale == 'ko') {
          return stock.name;
        } else {
          return stock.english_name;
        }
      },
    },
    watch: {
      '$route.path': function(val, oldVal) {
        this.initComponent();
      }
    },
  }
</script>