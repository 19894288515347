<template>
  <layout>
    <div class="banner-container community-banner" :style="backgroundImage('simulation', 'banner')">
      <div class="container">
        <p class="page-title b-24">
          {{ this.$translations('common', 'menu.community')}}
        </p>

        <div class="content">
          <div class="banner-title">
            <p>
              {{ this.$translations('community', 'banner.title1')}}
            </p>
            <p>
              {{ this.$translations('community', 'banner.title2')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="community-container container">
      <div class="content">
        <p class="page-name n-12">
          {{ this.$translations('common', 'menu.home')}} > {{ this.$translations('common', 'menu.community')}}
        </p>

        <div class="top-wrapper" :style="backgroundImage('simulation', 'top')">
          {{ this.$translations('common', 'menu.community')}}
        </div>

        <div class="slack-wrapper">
          <p class="sub-title">
            {{ this.$translations('community', 'slack.title')}}
          </p>

          <div class="desc">
            <p>
              {{ this.$translations('community', 'slack.desc1')}}
            </p>
            <p class="clearfix" :class="getLocale">
              <span class="l">{{ this.$translations('community', 'slack.desc4')}}</span>
              <a href="https://fmway.slack.com" class="sb">https://fmway.slack.com</a>
              <span class="t">{{ this.$translations('community', 'slack.desc2')}}</span>
            </p>
            <p>
              {{ this.$translations('community', 'slack.desc3')}}
            </p>
          </div>
        </div>

        <div class="slack-join">
          <p class="sub-title">
            {{ this.$translations('community', 'slack.join.title')}}
          </p>

          <div class="desc">
            <p>
              {{ this.$translations('community', 'slack.join.desc1')}}
            </p>
            <p>
              {{ this.$translations('community', 'slack.join.desc2')}}
            </p>
            <p>
              <span class="sb">{{ this.$translations('community', 'slack.join.desc3')}}</span>
              {{ this.$translations('community', 'slack.join.desc4')}}
            </p>
          </div>
        </div>

        <div class="button-wrapper">
          <div class="wrapper clearfix">
            <label class="name">
              {{ this.$translations('community', 'slack.invitation.title')}}
            </label>

            <input type="text" class="inp" :placeholder="this.$translations('community', 'slack.invitation.placeholder')" v-model="email" />

            <div class="btn" v-on:click="sendEmail">
              {{ this.$translations('community', 'slack.invitation.send')}}

            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../commons/layout';
  export default {
    name: 'home',
    data() {
      return {
        email: '',
        inpPlaceHolder: '',
      };
    },
    computed: {
      getLocale: function() {
        return window.I18n.locale;
      }
    },
    components: {
      Layout
    },
    methods: {
      sendEmail: function() {
        axios.post(`${window.apiHost}/communities/register`, {email: this.email}).then(res => {
          this.email = '';
        });
      },
      get_images: (page, key) => {
        const val = window.I18n.images[page][key] || '';
        return val;
      },
      backgroundImage: function(page, key) {
        const imageUrl = this.get_images(page, key);
        return `background-image: url('${imageUrl}')`;
      },
    }
  }
</script>