var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "banner-container" }, [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "page-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.$translations("common", "menu.about")) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("common", "title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("banner.description1")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("banner.description2")) +
                  "\n          "
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "about-container container bg-w" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "page-name n-12" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.$translations("common", "menu.home")) +
              " > " +
              _vm._s(this.$translations("common", "menu.about")) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section" }, [
          _c("p", { staticClass: "section-name" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.trans("summary.name")) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "section-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("summary.section.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("summary.section.desc")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "items clearfix" }, [
          _c("li", { staticClass: "item", class: _vm.getLocale }, [
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("summary.item1.title")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", [_vm._v(_vm._s(_vm.trans("summary.item1.desc1")))])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("summary.item2.title")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", [_vm._v(_vm._s(_vm.trans("summary.item2.desc1")))])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("summary.item3.title")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", [_vm._v(_vm._s(_vm.trans("summary.item3.desc1")))])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "about-container container bg-b" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "section" }, [
          _c("p", { staticClass: "section-name" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("algorithm.section.name")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "section-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("algorithm.section.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("algorithm.section.desc")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "analysis-wrapper clearfix", class: _vm.getLocale },
          [
            _c("ul", { staticClass: "data-list clearfix" }, [
              _c("li", { staticClass: "data" }, [
                _c("p", { staticClass: "data-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.trans("algorithm.data1.title")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data-con" }, [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data1.item1")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data1.item2")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data1.item3")) +
                        "\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "data" }, [
                _c("p", { staticClass: "data-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.trans("algorithm.data2.title")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data-con" }, [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data2.item1")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data2.item2")) +
                        "\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "data" }, [
                _c("p", { staticClass: "data-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.trans("algorithm.data3.title")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data-con" }, [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data3.item1")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data3.item2")) +
                        "\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "data" }, [
                _c("p", { staticClass: "data-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.trans("algorithm.data4.title")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data-con" }, [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data4.item1")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data4.item2")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data4.item3")) +
                        "\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "data" }, [
                _c("p", { staticClass: "data-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.trans("algorithm.data5.title")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data-con" }, [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data5.item1")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data5.item2")) +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data5.item3")) +
                        "\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "data" }, [
                _c("p", { staticClass: "data-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.trans("algorithm.data6.title")) +
                      "\n\n            "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "data-con" }, [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data6.item1")) +
                        "\n\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("algorithm.data6.item2")) +
                        "\n\n              "
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data-arrow" }, [
              _c("div", { staticClass: "arrow" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "big-data clearfix" }, [
              _c("img", {
                staticClass: "img",
                attrs: { src: _vm.get_images("about", "big_data") }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "img-names" }, [
                _c("p", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        this.$translations("about", "algorithm.image1.title1")
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        this.$translations("about", "algorithm.image1.title2")
                      ) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data-arrow" }, [
              _c("div", { staticClass: "arrow" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "algorithm clearfix" }, [
              _c("img", {
                staticClass: "img",
                attrs: { src: _vm.get_images("about", "algorithm") }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "img-names" }, [
                _c("p", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        this.$translations("about", "algorithm.image2.title1")
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        this.$translations("about", "algorithm.image2.title2")
                      ) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data-arrow" }, [
              _c("div", { staticClass: "arrow" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "stocks", class: _vm.getLocale }, [
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans("algorithm.stocks.item1")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans("algorithm.stocks.item2")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans("algorithm.stocks.item3")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans("algorithm.stocks.item4")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans("algorithm.stocks.item5")) +
                    "\n          "
                )
              ])
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "about-container container bg-w" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "section" }, [
          _c("p", { staticClass: "section-name" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.trans("summary.name")) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "section-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("summary.section.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("summary.section.desc")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "strategy-items clearfix" }, [
          _c("li", { staticClass: "item" }, [
            _c("p", { staticClass: "num" }, [
              _vm._v("\n            50\n          ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("strategy.item1.title")) +
                  "\n\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.trans("strategy.item1.desc1")) +
                    "\n            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", { staticClass: "num" }, [
              _vm._v("\n            3\n          ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("strategy.item2.title")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.trans("strategy.item2.desc1")) +
                    "\n            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", { staticClass: "num" }, [
              _vm._v("\n            4\n          ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "item-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("strategy.item3.title")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.trans("strategy.item3.desc1")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("p")
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "about-container container bg-b" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "section" }, [
          _c("p", { staticClass: "section-name" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("graph.section.name")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "section-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("graph.section.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("graph.section.desc")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "graph-wrapper" }, [
          _c("div", { staticClass: "exp-wrapper clearfix" }, [
            _c("div", { staticClass: "terms" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("graph.head.period")) +
                  " : 2001.6 ~ 2020.11 (78" +
                  _vm._s(_vm.trans("graph.head.quarter")) +
                  ")\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "country-info" }, [
              _vm._v("\n            BM : KOSPI / KOSDAQ Index\n          ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scroll" }, [
            _c("ul", { staticClass: "infos clearfix" }, [
              _c("li", { staticClass: "info" }, [
                _c("p", { staticClass: "rate" }, [
                  _vm._v("\n                57 (73%)\n              ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("graph.data.item1")) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "info" }, [
                _c("p", { staticClass: "rate" }, [
                  _vm._v("\n                16.4%\n              ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("graph.data.item2")) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "info" }, [
                _c("p", { staticClass: "rate" }, [
                  _vm._v("\n                -6.7%\n              ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("graph.data.item3")) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "info" }, [
                _c("p", { staticClass: "rate" }, [
                  _vm._v("\n                40.7%\n              ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("graph.data.item4")) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "info" }, [
                _c("p", { staticClass: "rate" }, [
                  _vm._v("\n                97,607%\n              ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "rate-desc" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("graph.data.item5")) +
                      "\n              "
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scroll" }, [
            _c(
              "div",
              { staticClass: "graph-img" },
              [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "about-container container bg-w" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "section" }, [
          _c("p", { staticClass: "section-name" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("characteristic.section.name")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "section-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.trans("characteristic.section.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("characteristic.section.desc")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "characteristic-items clearfix" }, [
          _c("li", { staticClass: "item" }, [
            _c("div", { staticClass: "item-title", class: _vm.getLocale }, [
              _c("p", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.trans("characteristic.item1.title")) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("p", { staticClass: "desc-title" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.trans("characteristic.item1.desc")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-rate" }, [
                _c("span", { staticClass: "rate" }, [_vm._v("39.7")]),
                _c("span", { staticClass: "unit" }, [_vm._v("%")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("div", { staticClass: "item-title", class: _vm.getLocale }, [
              _c("p", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.trans("characteristic.item2.title")) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-desc" }, [
              _c("ul", { staticClass: "desc-graphs clearfix" }, [
                _c("li", { staticClass: "graph" }, [
                  _c("img", {
                    staticClass: "g-img",
                    attrs: { src: _vm.get_images("about", "long_100") }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-desc" }, [
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans("characteristic.item2.desc1")) +
                          "\n                  "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "graph" }, [
                  _c("img", {
                    staticClass: "g-img",
                    attrs: { src: _vm.get_images("about", "long_70") }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-desc" }, [
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans("characteristic.item2.desc2")) +
                          "\n                  "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans("characteristic.item2.desc3")) +
                          "\n                  "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "graph" }, [
                  _c("img", {
                    staticClass: "g-img",
                    attrs: { src: _vm.get_images("about", "long_50") }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-desc" }, [
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans("characteristic.item2.desc4")) +
                          "\n                  "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans("characteristic.item2.desc5")) +
                          "\n                  "
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }