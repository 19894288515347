import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import HomeIndex from './components/home';
import AboutIndex from './components/about';
import SimulationIndex from './components/simulation';
import CountrySimulation from  './components/country_simulation';
import PortfolioIndex from './components/portfolio';
import CountryPortfolio from './components/country_portfolio';
import CommunityIndex from './components/community';

const router = new VueRouter({
  mode: 'history',
  // base: `${I18n.prefix}`,
  routes: [
    { path: '/', component: HomeIndex, name: 'home' },
    { path: '/about', component: AboutIndex, name: 'about' },
    { path: '/simulations', component: SimulationIndex, name: 'simulation' },
    { path: '/simulations/:id', component: CountrySimulation, name: 'country_simulation' },
    { path: '/portfolios', component: PortfolioIndex, name: 'portfolio' },
    { path: '/portfolios/:countryId', component: CountryPortfolio, name: 'country_portfolio' },
    { path: '/simulations/:country_code', component: CountrySimulation, name: 'country_simulation' },
    { path: '/community', component: CommunityIndex, name: 'community' },
    { path: '*', redirect: '/' },
  ],
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

export default router;