<template>
  <div class="country-summary">
    <div class="scroll">
      <div class="graph-wrapper">
        <highcharts :options="chartOptions" />
      </div>
    </div>

    <div class="table-wrapper">
      <p class="updated-info">
        {{ this.$translations('common', 'updated_date')}} : {{lastUpdatedDate}}
      </p>

      <div class="scroll">
        <ul class="summary-tables">
          <li class="row r-header clearfix">
            <div class="col">
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col1.name')}}
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col2.name')}}
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col3.name')}}
            </div>
            <div class="col">
              {{ this.$translations('portfolio', 'summary.col4.name')}}
            </div>
          </li>

          <li class="row clearfix">
            <div class="col">
              {{country.english_name}} Portfolio
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastPrice | formatNumber(1)}}
            </div>
            <div class="col">
              {{summary.long_only | formatNumber}}%
            </div>
            <div class="col">
              {{summary.long_mdd | formatNumber}}%
            </div>
          </li>

          <li class="row clearfix">
            <div class="col">
              {{country.english_name}} Portfolio(L/S)
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastLSPrice | formatNumber(1)}}
            </div>
            <div class="col">
              {{summary.long_short | formatNumber}}%
            </div>
            <div class="col">
              {{summary.long_short_mdd | formatNumber}}%
            </div>
          </li>

          <li class="row clearfix">
            <div class="col">
              {{bmName}}
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastBmPrice | formatNumber(1)}}
            </div>
            <div class="col">
              {{summary.bm | formatNumber}}%
            </div>
            <div class="col">
              {{summary.bm_mdd | formatNumber}}%
            </div>
          </li>

          <li class="row clearfix" v-if="bm2Name != null">
            <div class="col">
              {{bm2Name}}
            </div>
            <div class="col">
              1,000.0
            </div>
            <div class="col">
              {{lastBm2Price | formatNumber(1)}}
            </div>
            <div class="col">
              {{summary.bm2 | formatNumber}}%
            </div>
            <div class="col">
              {{summary.bm2_mdd | formatNumber}}%
            </div>
          </li>

        </ul>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    props: {
    },
    data() {
      return {
        country: {
          english_name: '',
          summary: {}
        },
        chartOptions: {
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
        },
        summary: {
          long_only: null,
          long_short: null,
          composite: null,
          long_mdd: null,
          long_short_mdd: null,
        },
        lastUpdatedDate: '',
        bmName: '',
        bm2Name: null,
      }
    },
    computed: {
      lastPrice: function() {
        const longOnly = this.summary.long_only;
        return longOnly === undefined ? 1000 : ((100 + longOnly) * 10)
      },
      lastLSPrice: function() {
        const longShort = this.summary.long_short;
        return longShort === undefined ? 1000 : ((100 + longShort) * 10);
      },
      lastBmPrice: function() {
        const longShort = this.summary.bm;
        return longShort === undefined ? 1000 : ((100 + longShort) * 10);
      },
      lastBm2Price: function() {
        const longShort = this.summary.bm2;
        return longShort === undefined ? 1000 : ((100 + longShort) * 10);
      }
    },
    mounted() {
      this.initComponent();
    },
    methods: {
      initComponent: function() {
        const curDom = this;
        axios.get(`${window.apiHost}/countries/${this.$route.params.countryId}/accumulated`) .then(res => {
          curDom.country = res.data.country;
          curDom.summary = res.data.accumulated.summary;
          curDom.bmName = res.data.bm_name;
          curDom.bm2Name = res.data.bm2_name;

          const countryName = curDom.country.english_name;
          var xaxis = [];
          var longOnlySeries = [];
          var longShortSerires = [];
          var bm = [];
          var bm2 = [];

          res.data.accumulated.accumulated.forEach(function (item, idx) {
            xaxis.push(item.trade_date);
            longOnlySeries.push(item.long_only);
            longShortSerires.push(item.long_short);
            bm.push(item.composite);
            bm2.push(item.bm2_composite);
            curDom.lastUpdatedDate = item.trade_date;
          });

          const options = {
            title: {
              text: ''
            },
            subtitle: {
              text: ''
            },
            // X축 설정
            xAxis: {
              reversed: false,
              categories: xaxis,
              gridLineWidth: 1,
              tickInterval: 3,
              startOnTick: true,
            },
            // Y축 설정
            yAxis: [
              {
                title: {
                  text: 'Profit'
                },
                labels: {
                  format: '{value} %'
                }
              }
            ],

            // 오른쪽 범례설정
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            },
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              }
            },
            // 라인 설정
            series: [
              {
                name: `${countryName} Portfolio`,
                data: longOnlySeries,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              },
              {
                name: `${countryName} Portfolio(Long Short)`,
                data: longShortSerires,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              },
              {
                name: curDom.bmName,
                data: bm,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              }
            ],
            colors: ['#FF5C00', '#82D443', '#2DBBC4', '#004589'],
          };

          // if (curDom.bm2Name !== undefined && curDom.bm2Name != null) {
          //   options.series.push({
          //     name: curDom.bm2Name,
          //     data: bm2,
          //     marker: {
          //       radius: 0,
          //       symbol: 'circle'
          //     }
          //   });
          // }

          curDom.chartOptions = options;
        });
      }
    },
    watch: {
      '$route.path': function(val, oldVal) {
        this.initComponent();
      }
    },
  }
</script>