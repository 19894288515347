<template>
  <layout>
    <div class="banner-container simulation-banner" :style="backgroundImage('simulation', 'banner')">
      <div class="container">
        <p class="page-title b-24">
          {{ this.$translations('common', 'menu.simulation')}} / {{simulation.country_name}}
        </p>

        <div class="content">
          <div class="banner-title">
            <p>
              {{ this.$translations('simulation', 'banner.title1')}}
            </p>
            <p>
              {{ this.$translations('simulation', 'banner.title2')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="simulation-container container">
      <div class="content">
        <p class="page-name n-12">
          {{ this.$translations('common', 'menu.home')}} > {{ this.$translations('common', 'menu.simulation')}} > {{simulation.country_name}}
        </p>

        <div class="country-info">
          <p class="name">
            {{simulation.country_name}}
          </p>

          <ul class="infos clearfix">
            <li class="info">
              <p class="info-title">
                {{ this.$translations('common', 'detail.target')}}
              </p>
              <div class="info-desc">
                <p>{{bmNames}}</p>
                <p>{{ this.$translations('common', 'detail.list')}}</p>
              </div>
            </li>

            <li class="info">
              <p class="info-title">
                {{ this.$translations('common', 'detail.period')}}
              </p>
              <p class="info-desc">
                {{investmentPeriod}}
              </p>
            </li>

            <li class="info">
              <p class="info-title">
                BM
              </p>
              <p class="info-desc">
                {{bmNames}}
              </p>
            </li>
          </ul>
        </div>

        <div class="country-summary">
          <div class="scroll">
            <div class="graph-wrapper">
              <highcharts :options="chartOptions" />
            </div>
          </div>

          <div class="table-wrapper">
            <div class="scroll">
              <ul class="summary-tables">
                <li class="row r-header clearfix">
                  <div class="col">
                  </div>
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.col1.name')}}
                  </div>
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.col2.name')}}
                  </div>
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.col3.name')}}
                  </div>
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.col4.name')}}
                  </div>
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.col5.name')}}
                  </div>
                </li>

                <li class="row clearfix">
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.row1.name')}}
                  </div>
                  <div class="col">
                    {{earningCount}}
                  </div>
                  <div class="col">
                    {{earningWinRate | formatNumber}}%
                  </div>
                  <div class="col">
                    {{earningAvgProfit | formatNumber}}%
                  </div>
                  <div class="col">
                  </div>
                  <div class="col">
                  </div>
                </li>

                <li class="row clearfix">
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.row2.name')}}
                  </div>
                  <div class="col">
                    {{lossCount}}
                  </div>
                  <div class="col">
                    {{lossRate | formatNumber}}%
                  </div>
                  <div class="col">
                    {{lossAvgProfit | formatNumber}}%
                  </div>
                  <div class="col">
                  </div>
                  <div class="col">
                  </div>
                </li>

                <li class="row sum clearfix">
                  <div class="col">
                    {{ this.$translations('simulation', 'detail.table1.row3.name')}}
                  </div>
                  <div class="col">
                    {{performances.length}}
                  </div>
                  <div class="col">
                    100%
                  </div>
                  <div class="col">
                    {{avgProfit | formatNumber}}%
                  </div>
                  <div class="col">
                    {{avgYearProfit | formatNumber}}%
                  </div>
                  <div class="col">
                    {{ performances.length > 0 ? (performances[performances.length - 1].accumulated_portfolio_earning) : 0}}%
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="outcome-wrapper">
          <p class="title">
            {{ this.$translations('simulation', 'detail.table2.name')}}
          </p>

          <div class="tab-wrapper">
            <div class="scroll">
              <ul class="tab">

                <li class="head row clearfix" :class="columnClass">
                  <div class="ss">
                    No
                  </div>
                  <div class="ss">
                    Year
                  </div>
                  <div class="ss">
                    Quarter
                  </div>
                  <div class="mm">
                    {{ this.$translations('simulation', 'detail.table2.transfer')}}
                  </div>
                  <div class="mm">
                    Portfolio
                  </div>
                  <div class="mm">
                    {{bm1Name}}
                  </div>
                  <div class="mm" v-if="bm2Name != null">
                    {{bm2Name}}
                  </div>
                  <div class="mm">
                    Portfolio {{ this.$translations('simulation', 'detail.table2.accumulated')}}
                  </div>
                  <div class="mm">
                    {{bm1Name}} {{ this.$translations('simulation', 'detail.table2.accumulated')}}
                  </div>
                  <div class="mm" v-if="bm2Name != null">
                    {{bm2Name}} {{ this.$translations('simulation', 'detail.table2.accumulated')}}
                  </div>
                </li>

                <li class="row clearfix" v-for="(performance, idx) in performances" :key="performance.id" :class="columnClass">
                  <div class="ss">
                    {{idx + 1}}
                  </div>
                  <div class="ss">
                    {{performance.year}}
                  </div>
                  <div class="ss">
                    {{performance.quarter}}Q
                  </div>
                  <div class="mm">
                    {{getPeriod(performance.quarter)}}
                  </div>
                  <div class="mm">
                    {{performance.portfolio_earning | formatNumber}}%
                  </div>
                  <div class="mm">
                    {{performance.bm1_earning | formatNumber}}%
                  </div>
                  <div class="mm" v-if="bm2Name != null">
                    {{performance.bm2_earning | formatNumber}}%
                  </div>
                  <div class="mm">
                    {{performance.accumulated_portfolio_earning | formatNumber }}%
                  </div>
                  <div class="mm">
                    {{performance.accumulated_bm1_earning | formatNumber }}%
                  </div>
                  <div class="mm" v-if="bm2Name != null">
                    {{performance.accumulated_bm2_earning | formatNumber }}%
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../commons/layout';
  export default {
    props: [],
    data() {
      return {
        simulation: {
          country_name: '',
        },
        country: {},
        countryMarkets: [],
        performances: [],
        chartOptions: {
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
        },
        bm1Name: '',
        bm2Name: null,
      }
    },
    computed: {
      columnClass: function() {
        if (this.bm2Name != null) {
          return '';
        }

        return 'exp';
      },
      bmNames: function() {
        var marketName = '';
        this.countryMarkets.forEach((market, idx) => {
          if (idx != 0) {
            marketName += " / ";
          }

          marketName += market.english_name;
        });
        return marketName;
      },
      investmentPeriod: function() {
        var period = '';
        if (this.performances.length > 0) {
          period += (this.performances[0].year + ". " + this.performances[0].quarter + "Q ~ ");
          const lastIdx = this.performances.length - 1;
          period += (this.performances[lastIdx].year + ". " + this.performances[lastIdx].quarter + "Q");
        }
        return period;
      },
      earningCount: function() {
        var count = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning > 0) {
            count += 1;
          }
        });
        return count;
      },
      lossCount: function() {
        var count = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning < 0) {
            count += 1;
          }
        });
        return count;
      },
      earningWinRate: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var count = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning > 0) {
            count += 1;
          }
        });

        return (count / this.performances.length) * 100;
      },
      lossRate: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var count = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning < 0) {
            count += 1;
          }
        });

        return (count / this.performances.length) * 100;
      },
      earningAvgProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var count = 0;
        var profit = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning > 0) {
            count += 1;
            profit += item.portfolio_earning;
          }
        });

        return profit / count;
      },

      earningYearAvgProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var profit = {};
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning > 0) {
            const key = item.year + "";
            var yearProfit = profit[key] || [];
            yearProfit.push(item.portfolio_earning);
            profit[key] = yearProfit;
          }
        });

        var keys = Object.keys(profit);
        var values = keys.map(k => {return profit[k];});
        var result = 0;
        values.forEach((v, idx) => {
          var vv = 0;
          v.forEach((v2, idx2) => {
            vv += v2;
          });

          result += (vv / v.length);
        });

        return result / values.length;
      },
      earningAccumulatedProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var profit = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning > 0) {
            profit += item.portfolio_earning;
          }
        });

        return profit;
      },

      lossYearAvgProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var profit = {};
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning < 0) {
            const key = item.year + "";
            var yearProfit = profit[key] || [];
            yearProfit.push(item.portfolio_earning);
            profit[key] = yearProfit;
          }
        });

        var keys = Object.keys(profit);
        var values = keys.map(k => {return profit[k];});
        var result = 0;
        values.forEach((v, idx) => {
          var vv = 0;
          v.forEach((v2, idx2) => {
            vv += v2;
          });

          result += (vv / v.length);
        });

        return result / values.length;
      },
      lossAccumulatedProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var profit = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning < 0) {
            profit += item.portfolio_earning;
          }
        });

        return profit;
      },

      lossAvgProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var count = 0;
        var profit = 0;
        this.performances.forEach((item, idx) => {
          if (item.portfolio_earning < 0) {
            count += 1;
            profit += item.portfolio_earning;
          }
        });

        return profit / count;
      },
      avgProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var profit = 0;
        this.performances.forEach((item, idx) => {
          profit += item.portfolio_earning;
        });

        return profit / this.performances.length;
      },
      avgYearProfit: function() {
        if (this.performances.length == 0) {
          return 0;
        }

        var profit = {};
        this.performances.forEach((item, idx) => {
          const key = item.year + "";
          var yearProfit = profit[key] || [];
          yearProfit.push(item.portfolio_earning);
          profit[key] = yearProfit;
        });

        var keys = Object.keys(profit);
        var values = keys.map(k => {return profit[k];});
        var result = 0;
        values.forEach((v, idx) => {
          var vv = 0;
          v.forEach((v2, idx2) => {
            vv += v2;
          });

          result += (vv / v.length);
        });

        return result / values.length;
      }
    },
    mounted() {
      this.initComponent();
    },
    components: {
      Layout
    },
    watch: {
      '$route.path': function(val, oldVal) {
        this.initComponent();
      }
    },
    methods: {
      get_images: (page, key) => {
        const val = window.I18n.images[page][key] || '';
        return val;
      },
      backgroundImage: function(page, key) {
        const imageUrl = this.get_images(page, key);
        return `background-image: url('${imageUrl}')`;
      },
      getPeriod: function(quarter) {
        if (quarter == 1) {
          return "06.01 ~ 08.31";
        } else if (quarter == 2) {
          return "09.01 ~ 11.30";
        } else if (quarter == 3) {
          return "12.01 ~ 02.28";
        } else {
          return "03.01~05.31";
        }
      },
      initComponent: function() {

        axios.get(`${window.apiHost}/simulations/${this.$route.params.id}`, {}).then(res => {
          this.simulation = res.data;
          this.country = res.data.country;
          this.countryMarkets = res.data.country_markets;
          this.performances = res.data.performances;

          if (res.data.country_markets == null) {
            this.$router.push({name: 'simulation'});
          }

          var bmName = '';
          var otherBmName = null;
          res.data.country_markets.forEach((item, idx) => {
            if (item.is_short === true) {
              bmName = item.english_name;
            } else if (item.is_short !== true) {
              otherBmName = item.english_name;
            }
          });

          if (bmName.length == 0 && otherBmName.length > 0) {
            bmName = otherBmName;
            otherBmName = null;
          }

          this.bm1Name = bmName;
          this.bm2Name = otherBmName;

          var xaxis = [];
          var portfolioRate = [];
          var bm1 = [];
          var bm2 = [];
          res.data.performances.forEach(function (item, idx) {
            xaxis.push(item.year + "." + item.quarter + "Q");
            portfolioRate.push(item.accumulated_portfolio_earning);
            bm1.push(item.accumulated_bm1_earning);

            if (item.accumulated_bm2_earning != null) {
              bm2.push(item.accumulated_bm2_earning);
            }
          });

          const options = {
            title: {
              text: ''
            },
            subtitle: {
              text: ''
            },
            // X축 설정
            xAxis: {
              reversed: false,
              categories: xaxis,
              gridLineWidth: 1,
              tickInterval: 3,
              startOnTick: true,
            },
            // Y축 설정
            yAxis: [
              {
                title: {
                  text: 'Profit'
                },
                labels: {
                  format: '{value} %'
                }
              }
            ],

            // 오른쪽 범례설정
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            },
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              }
            },
            // 라인 설정
            series: [
              {
                name: `Long Only`,
                data: portfolioRate,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              },
              {
                name: `${bmName}`,
                data: bm1,
                marker: {
                  radius: 0,
                  symbol: 'circle'
                }
              },
            ],
            colors: ['#FF5C00', '#2DBBC4', '#004589'],
          };

          if (otherBmName != null && bm2.length > 0 && otherBmName.length > 0) {
            options.series.push({
              name: `${otherBmName}`,
              data: bm2,
              marker: {
                radius: 0,
                symbol: 'circle'
              }
            });
          }

          this.chartOptions = options;
        });

      }
    }
  }
</script>