<template>
  <div class="mobile-menu-container container">
    <div class="content">
      <ul class="menus">
        <li class="menu" :class="{active: isActive('home')}">
          <router-link :to="{ name: 'home' }">
            {{ this.$translations('common', 'menu.home')}}
          </router-link>
        </li>
        <li class="menu" :class="{active: isActive('about')}">
          <router-link :to="{ name: 'about' }">
            {{ this.$translations('common', 'menu.about')}}
          </router-link>
        </li>

        <li class="sub-menu-wrapper clearfix" :class="{active: showSimulation}" v-on:click="showSimulation = !showSimulation">
          <div class="lang">
            {{ this.$translations('common', 'menu.simulation')}}
          </div>
          <div class="icon"></div>
        </li>
        <ul class="sub-menus" v-if="showSimulation">
          <li class="sub-menu clearfix">
            <router-link :to="{ name: 'simulation' }">
              <span v-on:click="clicked">{{ this.$translations('common', 'menu.summary')}}</span>
            </router-link>
          </li>
          <li class="sub-menu clearfix" v-for="simulation in simulations" :key="simulation.id">
            <router-link :to="{ name: 'country_simulation', params: {id: simulation.id} }">
              <span v-on:click="clicked">{{translate(simulation)}}</span>
            </router-link>
          </li>
        </ul>

        <li class="sub-menu-wrapper clearfix" :class="{active: showPortfolio}" v-on:click="showPortfolio = !showPortfolio">
          <div class="lang">
            {{ this.$translations('common', 'menu.portfolio')}}
          </div>
          <div class="icon"></div>
        </li>
        <ul class="sub-menus" v-if="showPortfolio">
          <li class="sub-menu clearfix">
            <router-link :to="{ name: 'portfolio' }">
              <span v-on:click="clicked">{{ this.$translations('common', 'menu.all')}}</span>
            </router-link>
          </li>
          <li class="sub-menu clearfix" v-for="portfolio in portfolios" :key="portfolio.id">
            <router-link :to="{ name: 'country_portfolio', params: {countryId: portfolio.id} }">
              <span v-on:click="clicked">{{translatePortName(portfolio)}}</span>
            </router-link>
          </li>
        </ul>

        <li class="menu" :class="{active: isActive('community')}">
          <router-link :to="{ name: 'community' }">
            {{ this.$translations('common', 'menu.community')}}
          </router-link>
        </li>

        <li class="sub-menu-wrapper clearfix" :class="{active: showLanguage}" v-on:click="showLanguage = !showLanguage">
          <div class="lang">Language</div>
          <div class="icon"></div>
        </li>
        <ul class="sub-menus" v-if="showLanguage">
          <li class="sub-menu clearfix" v-on:click="setLocale('ko')">
            <div class="flag ko"></div>
            <div class="flag-name">한국어</div>
          </li>
          <li class="sub-menu clearfix" v-on:click="setLocale('en')">
            <div class="flag en"></div>
            <div class="flag-name">English</div>
          </li>
          <li class="sub-menu clearfix" v-on:click="setLocale('zh')">
            <div class="flag zh"></div>
            <div class="flag-name">简体中文</div>
          </li>
        </ul>

      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      portfolios: {
        type: Array,
        default: [],
      },
      simulations: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        showLanguage: false,
        showSimulation: false,
        showPortfolio: false,
      }
    },
    methods: {
      isActive: function(routeName) {
        return this.$route.name == routeName;
      },
      setLocale: function(locale) {
        this.$cookies.set("locale", locale);
        location.reload();
      },
      translate: function(simulation) {
        if (this.locale == 'zh') {
          return simulation.chinese_country_name;
        } else {
          return simulation.english_country_name;
        }
      },
      translatePortName: function (portfolio) {
        if (this.locale == 'zh') {
          return portfolio.chinese_name;
        } else {
          return portfolio.english_name.toUpperCase();
        }
      },
      clicked: function() {
        this.$emit("hide-mobile-menu");
      }
    }
  }
</script>