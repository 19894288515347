var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "logo-wrapper clearfix" },
          [
            _c("router-link", {
              staticClass: "logo",
              attrs: { to: { name: "home" } }
            }),
            _vm._v(" "),
            _c("ul", { staticClass: "menus clearfix" }, [
              _c(
                "li",
                { staticClass: "menu" },
                [
                  _c("router-link", { attrs: { to: { name: "about" } } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(this.$translations("common", "menu.about")) +
                        "\n            "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu" },
                [
                  _c("router-link", { attrs: { to: { name: "simulation" } } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          this.$translations("common", "menu.simulation")
                        ) +
                        "\n            "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu" },
                [
                  _c("router-link", { attrs: { to: { name: "portfolio" } } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(this.$translations("common", "menu.portfolio")) +
                        "\n            "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu" },
                [
                  _c("router-link", { attrs: { to: { name: "community" } } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(this.$translations("common", "menu.community")) +
                        "\n            "
                    )
                  ])
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright-wrapper clearfix" }, [
      _c("p", { staticClass: "company" }, [_vm._v("FMway  Co., Ltd.")]),
      _vm._v(" "),
      _c("p", { staticClass: "mail" }, [
        _c("a", { attrs: { href: "mailto:fmwayinvest@gmail.com" } }, [
          _vm._v("fmwayinvest@gmail.com")
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "copyright" }, [
        _vm._v(
          "\n          Copyright ⓒ 2021 FMway Invest All rights reserved.\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }