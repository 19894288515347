var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "banner-container simulation-banner",
        style: _vm.backgroundImage("simulation", "banner")
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "page-title b-24" }, [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.simulation")) +
                " / " +
                _vm._s(_vm.simulation.country_name) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "banner-title" }, [
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("simulation", "banner.title1")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("simulation", "banner.title2")) +
                    "\n          "
                )
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "simulation-container container" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "page-name n-12" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.$translations("common", "menu.home")) +
              " > " +
              _vm._s(this.$translations("common", "menu.simulation")) +
              " > " +
              _vm._s(_vm.simulation.country_name) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "country-info" }, [
          _c("p", { staticClass: "name" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.simulation.country_name) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "infos clearfix" }, [
            _c("li", { staticClass: "info" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(this.$translations("common", "detail.target")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info-desc" }, [
                _c("p", [_vm._v(_vm._s(_vm.bmNames))]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(this.$translations("common", "detail.list")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "info" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(this.$translations("common", "detail.period")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-desc" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.investmentPeriod) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "info" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n              BM\n            ")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-desc" }, [
                _vm._v(
                  "\n              " + _vm._s(_vm.bmNames) + "\n            "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "country-summary" }, [
          _c("div", { staticClass: "scroll" }, [
            _c(
              "div",
              { staticClass: "graph-wrapper" },
              [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-wrapper" }, [
            _c("div", { staticClass: "scroll" }, [
              _c("ul", { staticClass: "summary-tables" }, [
                _c("li", { staticClass: "row r-header clearfix" }, [
                  _c("div", { staticClass: "col" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.col1.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.col2.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.col3.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.col4.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.col5.name"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "row clearfix" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.row1.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.earningCount) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("formatNumber")(_vm.earningWinRate)) +
                        "%\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("formatNumber")(_vm.earningAvgProfit)) +
                        "%\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" })
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "row clearfix" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.row2.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.lossCount) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("formatNumber")(_vm.lossRate)) +
                        "%\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("formatNumber")(_vm.lossAvgProfit)) +
                        "%\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" })
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "row sum clearfix" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          this.$translations(
                            "simulation",
                            "detail.table1.row3.name"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.performances.length) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v("\n                  100%\n                ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("formatNumber")(_vm.avgProfit)) +
                        "%\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm._f("formatNumber")(_vm.avgYearProfit)) +
                        "%\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          _vm.performances.length > 0
                            ? _vm.performances[_vm.performances.length - 1]
                                .accumulated_portfolio_earning
                            : 0
                        ) +
                        "%\n                "
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "outcome-wrapper" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("simulation", "detail.table2.name")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tab-wrapper" }, [
            _c("div", { staticClass: "scroll" }, [
              _c(
                "ul",
                { staticClass: "tab" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "head row clearfix",
                      class: _vm.columnClass
                    },
                    [
                      _c("div", { staticClass: "ss" }, [
                        _vm._v("\n                  No\n                ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ss" }, [
                        _vm._v("\n                  Year\n                ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ss" }, [
                        _vm._v("\n                  Quarter\n                ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mm" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              this.$translations(
                                "simulation",
                                "detail.table2.transfer"
                              )
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mm" }, [
                        _vm._v(
                          "\n                  Portfolio\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mm" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.bm1Name) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.bm2Name != null
                        ? _c("div", { staticClass: "mm" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.bm2Name) +
                                "\n                "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mm" }, [
                        _vm._v(
                          "\n                  Portfolio " +
                            _vm._s(
                              this.$translations(
                                "simulation",
                                "detail.table2.accumulated"
                              )
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mm" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.bm1Name) +
                            " " +
                            _vm._s(
                              this.$translations(
                                "simulation",
                                "detail.table2.accumulated"
                              )
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.bm2Name != null
                        ? _c("div", { staticClass: "mm" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.bm2Name) +
                                " " +
                                _vm._s(
                                  this.$translations(
                                    "simulation",
                                    "detail.table2.accumulated"
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.performances, function(performance, idx) {
                    return _c(
                      "li",
                      {
                        key: performance.id,
                        staticClass: "row clearfix",
                        class: _vm.columnClass
                      },
                      [
                        _c("div", { staticClass: "ss" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(idx + 1) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ss" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(performance.year) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ss" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(performance.quarter) +
                              "Q\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.getPeriod(performance.quarter)) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatNumber")(
                                  performance.portfolio_earning
                                )
                              ) +
                              "%\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatNumber")(performance.bm1_earning)
                              ) +
                              "%\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.bm2Name != null
                          ? _c("div", { staticClass: "mm" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      performance.bm2_earning
                                    )
                                  ) +
                                  "%\n                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "mm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatNumber")(
                                  performance.accumulated_portfolio_earning
                                )
                              ) +
                              "%\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatNumber")(
                                  performance.accumulated_bm1_earning
                                )
                              ) +
                              "%\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.bm2Name != null
                          ? _c("div", { staticClass: "mm" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      performance.accumulated_bm2_earning
                                    )
                                  ) +
                                  "%\n                "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }