import Vue from 'vue';
import VueI18n from 'vue-i18n';
import router from '../service/routes';
import App from '../service/app'
import VueCookies from 'vue-cookies';
import common from '../lib/common';
import translation from '../lib/translation';
import HighchartsVue from 'highcharts-vue';

import NavTop from '../service/commons/nav_top';

Vue.component('nav-top', NavTop);

window._ = require('lodash');
window.moment = require('moment');
window.common = common;
window.axios = require('axios');
axios.defaults.headers.common['Accept'] = 'application/json';

// 요청 인터셉터
axios.interceptors.request.use(function (config) {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  config.headers.common["X-CSRF-Token"] = csrfToken;
  return config;
}, function (error) {
  return Promise.reject(error);
});

Vue.use(HighchartsVue);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(translation);
const i18n = new VueI18n({
  locale: 'current',
  messages: translations
});

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');

Vue.filter('formatNumber', function(number, fixedSize) {
  return common.numberWithCommas(number, fixedSize);
});
