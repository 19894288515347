<template>
  <layout>

    <div class="banner-container simulation-banner" :style="backgroundImage('simulation', 'banner')">
      <div class="container">
        <p class="page-title b-24">
          {{ this.$translations('common', 'menu.simulation')}}
        </p>

        <div class="content">
          <div class="banner-title">
            <p>
              {{ this.$translations('simulation', 'banner.title1')}}
            </p>
            <p>
              {{ this.$translations('simulation', 'banner.title2')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="simulation-container container">
      <div class="content">
        <p class="page-name n-12">
          {{ this.$translations('common', 'menu.home')}} > {{ this.$translations('common', 'menu.simulation')}}
        </p>

        <div class="top-wrapper" :style="backgroundImage('simulation', 'top')">
          {{ this.$translations('common', 'menu.simulation')}}
        </div>

        <div class="container-summary">
          <p class="summary-title">
            {{ this.$translations('simulation', 'summary.title')}}

          </p>

          <div class="summary">
            <p>
              {{ this.$translations('simulation', 'summary.desc1')}}

            </p>
            <p>
              {{ this.$translations('simulation', 'summary.desc2')}}

            </p>
            <p>
              {{ this.$translations('simulation', 'summary.desc3')}}

            </p>
          </div>
        </div>

        <div class="back-test-wrapper">
          <p class="title">
            Back test Simulation Summary
          </p>

          <ul class="items clearfix">

            <li class="item">
              <div class="top-wra">
                <p class="item-title">
                  {{ this.$translations('simulation', 'backtest.item1.title')}}

                </p>
                <p class="item-desc">
                  {{ this.$translations('simulation', 'backtest.item1.desc')}}

                </p>
              </div>

              <div class="rate-wrapper">
                <div class="rate-desc" :class="locale">
                  <p>
                    {{ this.$translations('simulation', 'backtest.item1.ratedesc')}}
                  </p>
                </div>
                <p class="rate">
                  {{ this.$translations('simulation', 'backtest.item1.rate')}} <span class="unit">%</span>
                </p>
              </div>
            </li>

            <li class="item">
              <div class="top-wra">
                <p class="item-title">
                  {{ this.$translations('simulation', 'backtest.item2.title')}}
                </p>
              </div>

              <div class="rate-wrapper">
                <div class="rate-desc" :class="locale">
                  <p>
                    {{ this.$translations('simulation', 'backtest.item2.ratedesc')}}
                  </p>
                </div>
                <p class="rate">
                  {{ this.$translations('simulation', 'backtest.item2.rate')}} <span class="unit">%</span>
                </p>
              </div>
            </li>

            <li class="item">
              <div class="top-wra">
                <p class="item-title">
                  {{ this.$translations('simulation', 'backtest.item3.title')}}
                </p>
              </div>

              <div class="rate-wrapper">
                <div class="rate-desc" :class="locale">
                  <p>
                    {{ this.$translations('simulation', 'backtest.item3.ratedesc')}}
                  </p>
                </div>
                <p class="rate">
                  {{ this.$translations('simulation', 'backtest.item3.rate')}} <span class="unit">%</span>
                </p>
              </div>
            </li>

            <li class="item">
              <div class="top-wra">
                <p class="item-title">
                  {{ this.$translations('simulation', 'backtest.item4.title')}}
                </p>
              </div>

              <div class="rate-wrapper">
                <div class="rate-desc" :class="locale">
                  <p>
                    {{ this.$translations('simulation', 'backtest.item4.ratedesc')}}
                  </p>
                </div>
                <p class="rate">
                  {{ this.$translations('simulation', 'backtest.item4.rate')}} <span class="unit">%</span>
                </p>
              </div>
            </li>

            <li class="item">
              <div class="top-wra">
                <p class="item-title">
                  {{ this.$translations('simulation', 'backtest.item5.title')}}

                </p>
                <p class="item-desc">
                  {{ this.$translations('simulation', 'backtest.item5.desc')}}

                </p>
              </div>

              <div class="rate-wrapper">
                <p class="rate">
                  {{ this.$translations('simulation', 'backtest.item5.rate')}}<span class="unit">%</span>
                </p>
              </div>
            </li>

            <li class="item">
              <div class="top-wra">
                <p class="item-title">
                  {{ this.$translations('simulation', 'backtest.item6.title')}}

                </p>
                <p class="item-desc">
                  {{ this.$translations('simulation', 'backtest.item6.desc')}}

                </p>
              </div>

              <div class="rate-wrapper">
                <div class="rate-desc" :class="locale">
                  <p>
                    {{ this.$translations('simulation', 'backtest.item6.ratedesc')}}

                  </p>
                </div>
                <p class="rate">
                  {{ this.$translations('simulation', 'backtest.item6.rate')}}<span class="unit">%</span>
                </p>
              </div>
            </li>

          </ul>
        </div>

        <div class="country-wrapper">
          <ul class="countries">

            <li class="row r-header clearfix">
              <div class="no">
                No.
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col1')}}
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col2')}}
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col3')}}
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col4')}}
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col5')}}
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col6')}}
              </div>
              <div class="col">
                {{ this.$translations('simulation', 'table.col7')}}
              </div>
            </li>

            <li class="row clearfix" v-for="(simulation, idx) in simulations" :key="simulation.id">
              <div class="no">
                {{idx + 1}}
              </div>
              <div class="col">
                {{simulation.country_name}}
              </div>
              <div class="col">
                {{simulation.stock_count | formatNumber(0)}}
              </div>
              <div class="col">
                {{simulation.investment_y_period}}
              </div>
              <div class="col">
                {{simulation.investment_t_period}}
              </div>
              <div class="col">
                {{simulation.winning_rate | formatNumber}}%
              </div>
              <div class="col">
                {{simulation.avg_t_earning_rate | formatNumber}}%
              </div>
              <div class="col">
                {{simulation.avg_y_earning_rate | formatNumber}}%
              </div>
            </li>

            <li class="row sum clearfix">
              <div class="name">
                SUM
              </div>
              <div class="col">
                {{sumOfStocks | formatNumber(0)}}
              </div>
              <div class="col">
                {{sumOfYear | formatNumber(0)}}
              </div>
              <div class="col">
                {{sumOfQuarter | formatNumber(0)}}
              </div>
              <div class="col">
                {{avgOfWinning | formatNumber}}%
              </div>
              <div class="col">
                {{avgOfEarningQuarter | formatNumber}}%
              </div>
              <div class="col">
                {{avgOfEarningYear | formatNumber}}%
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../commons/layout';
  export default {
    name: 'home',
    data() {
      return {
        simulations: [],
      };
    },
    computed: {
      locale: function() {
        return window.I18n.locale;
      },
      sumOfStocks: function() {
        var count = 0;
        this.simulations.forEach((sim, idx) => {
          count += sim.stock_count;
        });

        return count;
      },
      sumOfYear: function() {
        var count = 0;
        this.simulations.forEach((sim, idx) => {
          count += sim.investment_y_period;
        });

        return count;
      },
      sumOfQuarter: function() {
        var count = 0;
        this.simulations.forEach((sim, idx) => {
          count += sim.investment_t_period;
        });

        return count;
      },
      avgOfWinning: function() {
        if (this.simulations.length == 0) {
          return 0;
        }

        var count = 0;
        this.simulations.forEach((sim, idx) => {
          count += sim.winning_rate;
        });

        return count / this.simulations.length;
      },
      avgOfEarningQuarter: function() {
        if (this.simulations.length == 0) {
          return 0;
        }

        var count = 0;
        this.simulations.forEach((sim, idx) => {
          count += sim.avg_t_earning_rate;
        });

        return count / this.simulations.length;
      },
      avgOfEarningYear: function() {
        if (this.simulations.length == 0) {
          return 0;
        }

        var count = 0;
        this.simulations.forEach((sim, idx) => {
          count += sim.avg_y_earning_rate;
        });

        return count / this.simulations.length;
      },
    },
    mounted() {
      axios.get(`${window.apiHost}/simulations`, {}).then(res => {
        this.simulations = res.data;
      })
    },
    components: {
      Layout
    },
    methods: {
      trans: function(key) {
        const val = window.I18n.messages['about'][key] || '';
        return val;
      },
      get_images: (page, key) => {
        const val = window.I18n.images[page][key] || '';
        return val;
      },
      backgroundImage: function(page, key) {
        const imageUrl = this.get_images(page, key);
        return `background-image: url('${imageUrl}')`;
      },
    }
  }
</script>