<template>
  <div class='container'>
    <nav-top />

    <div class='body-row'>
      <slot/>
    </div>

    <footer-bottom />
  </div>
</template>

<script>
  import FooterBottom from './footer';

  export default {
    components: {
      FooterBottom,
    },
    methods: {
      goTop: function() {
        window.scrollTo(0, 0);
      }
    }
  }
</script>
