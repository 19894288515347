var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "banner-container community-banner",
        style: _vm.backgroundImage("simulation", "banner")
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "page-title b-24" }, [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.community")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "banner-title" }, [
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("community", "banner.title1")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(this.$translations("community", "banner.title2")) +
                    "\n          "
                )
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "community-container container" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "page-name n-12" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.$translations("common", "menu.home")) +
              " > " +
              _vm._s(this.$translations("common", "menu.community")) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "top-wrapper",
            style: _vm.backgroundImage("simulation", "top")
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(this.$translations("common", "menu.community")) +
                "\n      "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "slack-wrapper" }, [
          _c("p", { staticClass: "sub-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("community", "slack.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("community", "slack.desc1")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "clearfix", class: _vm.getLocale }, [
              _c("span", { staticClass: "l" }, [
                _vm._v(_vm._s(this.$translations("community", "slack.desc4")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "sb",
                  attrs: { href: "https://fmway.slack.com" }
                },
                [_vm._v("https://fmway.slack.com")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "t" }, [
                _vm._v(_vm._s(this.$translations("community", "slack.desc2")))
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("community", "slack.desc3")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "slack-join" }, [
          _c("p", { staticClass: "sub-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(this.$translations("community", "slack.join.title")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("community", "slack.join.desc1")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("community", "slack.join.desc2")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "sb" }, [
                _vm._v(
                  _vm._s(this.$translations("community", "slack.join.desc3"))
                )
              ]),
              _vm._v(
                "\n            " +
                  _vm._s(this.$translations("community", "slack.join.desc4")) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-wrapper" }, [
          _c("div", { staticClass: "wrapper clearfix" }, [
            _c("label", { staticClass: "name" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    this.$translations("community", "slack.invitation.title")
                  ) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "inp",
              attrs: {
                type: "text",
                placeholder: this.$translations(
                  "community",
                  "slack.invitation.placeholder"
                )
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "btn", on: { click: _vm.sendEmail } }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    this.$translations("community", "slack.invitation.send")
                  ) +
                  "\n\n          "
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }