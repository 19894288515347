var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { w: _vm.isWhiteHeader && !_vm.showMobileMenu },
      staticStyle: { height: "auto", position: "relative" }
    },
    [
      _c(
        "div",
        {
          staticClass: "header clearfix",
          class: { w: _vm.isWhiteHeader && !_vm.showMobileMenu }
        },
        [
          _c(
            "div",
            {
              staticClass: "menu-wrapper clearfix",
              class: { w: _vm.isWhiteHeader && !_vm.showMobileMenu }
            },
            [
              _c("router-link", {
                staticClass: "logo",
                attrs: { to: { name: "home" } }
              }),
              _vm._v(" "),
              _c("ul", { staticClass: "menus clearfix" }, [
                _c(
                  "li",
                  {
                    staticClass: "menu kr-14",
                    class: { active: _vm.isActive("home") },
                    on: {
                      mouseenter: function($event) {
                        return _vm.hideSubMenu(["simulation", "portfolio"])
                      }
                    }
                  },
                  [
                    _c("router-link", { attrs: { to: { name: "home" } } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(this.$translations("common", "menu.home")) +
                          "\n          "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "menu kr-14",
                    class: { active: _vm.isActive("about") },
                    on: {
                      mouseenter: function($event) {
                        return _vm.hideSubMenu(["simulation", "portfolio"])
                      }
                    }
                  },
                  [
                    _c("router-link", { attrs: { to: { name: "about" } } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(this.$translations("common", "menu.about")) +
                          "\n          "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "menu kr-14",
                    class: { active: _vm.isActive("simulation") },
                    on: {
                      mouseenter: function($event) {
                        return _vm.hideSubMenu(["portfolio"])
                      }
                    }
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "simulation" } } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              this.$translations("common", "menu.simulation")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "menu kr-14",
                    class: { active: _vm.isActive("portfolio") },
                    on: {
                      mouseenter: function($event) {
                        return _vm.hideSubMenu(["simulation"])
                      }
                    }
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "portfolio" } } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              this.$translations("common", "menu.portfolio")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "menu kr-14",
                    class: { active: _vm.isActive("community") },
                    on: {
                      mouseenter: function($event) {
                        return _vm.hideSubMenu(["simulation", "portfolio"])
                      }
                    }
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "community" } } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              this.$translations("common", "menu.community")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "mobile-menu",
            class: {
              w: _vm.isWhiteHeader && !_vm.showMobileMenu,
              close: _vm.showMobileMenu
            },
            on: { click: _vm.setMobileMenu }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "lang-wrapper" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", {
                staticClass: "current-flag flag",
                class: _vm.locale,
                on: {
                  click: function($event) {
                    _vm.showLocale = !_vm.showLocale
                  }
                }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "down",
                class: { w: _vm.isWhiteHeader }
              })
            ]),
            _vm._v(" "),
            _vm.showLocale
              ? _c("ul", { staticClass: "flags" }, [
                  _c("li", {
                    staticClass: "flag ko",
                    on: {
                      click: function($event) {
                        return _vm.setLocale("ko")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("li", {
                    staticClass: "flag en",
                    on: {
                      click: function($event) {
                        return _vm.setLocale("en")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("li", {
                    staticClass: "flag zh",
                    on: {
                      click: function($event) {
                        return _vm.setLocale("zh")
                      }
                    }
                  })
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _vm.showMobileMenu
        ? _c("mobile-menu", {
            attrs: { simulations: _vm.simulations, portfolios: _vm.portfolios },
            on: { "hide-mobile-menu": _vm.setMobileMenu }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.displaySubMenu
        ? _c(
            "div",
            {
              staticClass: "sub-header-menus clearfix",
              class: { w: _vm.isWhiteHeader },
              on: {
                mouseleave: function($event) {
                  _vm.displaySubMenu = false
                }
              }
            },
            [
              _c(
                "ul",
                { staticClass: "menu-list" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "menu",
                      on: {
                        click: function($event) {
                          _vm.displaySubMenu = false
                        }
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "simulation" } } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                this.$translations("common", "menu.summary")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.simulations, function(simulation) {
                    return _c(
                      "li",
                      {
                        key: simulation.id,
                        staticClass: "menu",
                        on: {
                          click: function($event) {
                            _vm.displaySubMenu = false
                          }
                        }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "country_simulation",
                                params: { id: simulation.id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.translateSimulName(simulation)) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayPortfolioSubMenu
        ? _c(
            "div",
            {
              staticClass: "sub-header-menus portfolios clearfix",
              class: { w: _vm.isWhiteHeader },
              on: {
                mouseleave: function($event) {
                  _vm.displayPortfolioSubMenu = false
                }
              }
            },
            [
              _c(
                "ul",
                { staticClass: "menu-list portfol" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "menu",
                      on: {
                        click: function($event) {
                          _vm.displayPortfolioSubMenu = false
                        }
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "portfolio" } } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(this.$translations("common", "menu.all")) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.portfolios, function(portfolio) {
                    return _c(
                      "li",
                      {
                        key: portfolio.id,
                        staticClass: "menu",
                        on: {
                          click: function($event) {
                            _vm.displayPortfolioSubMenu = false
                          }
                        }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "country_portfolio",
                                params: { countryId: portfolio.id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.translatePortName(portfolio)) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }