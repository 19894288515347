<template>
  <layout>
    <div class="banner-container portfolio-banner" :style="backgroundImage('portfolio', 'banner')">
      <div class="container">
        <p class="page-title b-24">
          {{ this.$translations('common', 'menu.portfolio')}} / {{country.english_name}}
        </p>

        <div class="content">
          <div class="banner-title">
            <p>
              {{ this.$translations('portfolio', 'banner.title1')}}
            </p>
            <p>
              {{ this.$translations('portfolio', 'banner.title2')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="portfolio-container container">
      <div class="content">
        <p class="page-name n-12">
          {{ this.$translations('common', 'menu.home')}} > {{ this.$translations('common', 'menu.portfolio')}} > {{country.english_name}}
        </p>

        <div class="country-info">
          <p class="name">
            {{country.english_name}}
          </p>

          <ul class="infos clearfix">
            <li class="info">
              <p class="info-title">
                {{ this.$translations('common', 'detail.target')}}
              </p>
              <div class="info-desc">
                <p>{{bmNames}}</p>
                <p>{{ this.$translations('common', 'detail.list')}}</p>
              </div>
            </li>

            <li class="info">
              <p class="info-title">
                {{ this.$translations('common', 'detail.period')}}
              </p>
              <p class="info-desc">
                {{ portfolioStartDate }} ~ {{ this.$translations('common', 'detail.period.present')}}
              </p>
            </li>

            <li class="info">
              <p class="info-title">
                BM
              </p>
              <p class="info-desc">
                {{bmNames}}
              </p>
            </li>

            <li class="info">
              <p class="info-title">
                {{ this.$translations('portfolio', 'summary.col3.name') }}
              </p>
              <p class="info-desc">
                {{ getLongOnly | formatNumber}}%
              </p>
            </li>
          </ul>
        </div>

        <div class="tabs clearfix">
          <div class="tab" v-on:click="tab=0" :class="{active: tab==0}">
            {{country.english_name}} Portfolio
          </div>
          <div class="tab" v-on:click="tab=1" :class="{active: tab==1}">
            {{country.english_name}} Accumulated
          </div>
        </div>

        <country-portfolio-index v-if="tab==0" @clicked="clickedPortfolio" />
        <country-accumulated v-else-if="tab==1" />
      </div>
    </div>

    <portfolio-popup v-if="portfolioPop" :portfolioId="selectedPortfolioId" @close="closePop" />
  </layout>
</template>

<script>
  import Layout from '../commons/layout';
  import CountryPortfolioIndex from "./portfolios/index";
  import CountryAccumulated from "./portfolios/accumulated";
  import PortfolioPopup from "./portfolios/stock";

  export default {
    name: 'country_portfolio',
    props: {},
    data() {
      return {
        tab: 0,
        country: {
          english_name: '',
          bm: {},
          bms: [],
        },
        portfolioPop: false,
        selectedPortfolioId: 0,
      };
    },
    computed: {
      portfolioStartDate: function() {
        if (this.tab == 0) {
          return this.country.start_date;
        } else {
          return "2020.06.01";
        }
      },
      getLongOnly: function() {
        if(this.country.quarter_profit){
          if (this.tab == 0) {
            return this.country.quarter_profit.quarter_long_only * 100;
          }

          return this.country.quarter_profit.long_only * 100;
        }
        return 0;
      },
      bmNames: function() {
        var marketName = '';
        this.country.bms.forEach((market, idx) => {
          if (idx != 0) {
            marketName += " / ";
          }

          marketName += market.english_name;
        });

        return marketName;
      },
    },
    components: {
      Layout, CountryPortfolioIndex, CountryAccumulated, PortfolioPopup
    },
    mounted() {
      this.initComponent();
    },
    methods: {
      clickedPortfolio: function(portfolioId) {
        if (!this.country.blind_portfolio_info) {
          this.selectedPortfolioId = portfolioId;
          this.portfolioPop = true;
        }
      },
      closePop: function() {
        this.portfolioPop = false;
      },
      initComponent: function() {
        this.portfolioPop = false;
        this.selectedPortfolioId = null;
        this.country = {english_name: '', bm: {}, bms: []};

        axios.get(`${window.apiHost}/countries/${this.$route.params.countryId}`, {}).then(res => {
          this.country = res.data;
        });
      },
      get_images: (page, key) => {
        const val = window.I18n.images[page][key] || '';
        return val;
      },
      backgroundImage: function(page, key) {
        const imageUrl = this.get_images(page, key);
        return `background-image: url('${imageUrl}')`;
      },
    },
    watch: {
      '$route.path': function(val, oldVal) {
        this.initComponent();
      }
    },
  }
</script>